import React, { useEffect, useState, useRef } from "react";
import { makeStyles } from '@material-ui/core/styles';

import MyTable from '../../utils/MyTable';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';


//Redux
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from 'react-router-dom'
import allActions from "../../../actions";

import moment from 'moment'

// Utils
import _, { isEmpty } from 'lodash';
import { formatPortfolioClosed, getConsultingId, getMonths, getShortMonthName } from "../../utils/utils";
import { ClickAwayListener, Grow, Menu, MenuItem, MenuList, Paper, Popper, TableFooter, Tooltip, Typography } from "@material-ui/core";
import { headerSearch, unoTheme } from "../../../assets/styles/unoTheme";

import {
    doGetStatusClientsByYear, STATUS_DPIN, STATUS_DAIR
} from "../../../controllers/ClientController";

import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import SearchBar from "../../utils/SearchBar";
import { changeStatusDair, changeStatusDpin, getStatusesDair, getStatusesDpin } from "../../../API/status_dpin_dair";
import { formatStatusesByShortName } from "../../../controllers/StatusDpinDairController";
import MyAutoComplete from "../../utils/MyAutoComplete";
import { getActiveAdvisorsAndAdminsByConsulting } from "../../../API/user";
import { checkUserIsRole, roles } from "../../utils/user";
import { path } from "../../utils/routesPaths";
import { getClientById } from "../../../API/client";
import UnoButton from "../../utils/UnoButton";
import MyDatePicker from '../../utils/MyDatePicker'

import FilterListIcon from '@material-ui/icons/FilterList';
import MySelect2 from "../../utils/MySelect2";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'left',
    },

    headerSearch: headerSearch,

    myTableFooter: {
        cursor: "pointer",
        borderBottom: 'unset',
        '& > *': {
            borderBottom: 'unset',
        },

        '& .totalLineInvestiments': {
            '& .MuiTableCell-root': {
                color: 'black',
                borderBottom: 'unset',
                background: unoTheme.mainGrayColor,
            },
        },

        '& .totalLinePL': {
            '& .MuiTableCell-root': {
                borderBottom: 'unset',
                color: 'white',
                background: unoTheme.mainColor,
            },
        },

        '& .disponibilityLine': {
            '& .MuiTableCell-footer': {
                color: 'black',
            },
        },

        '& .MuiTableCell-root:first-child': {
            borderTopLeftRadius: '10px',
            borderBottomLeftRadius: '10px',
        },

        '& .MuiTableCell-root:last-child': {
            borderTopRightRadius: '10px',
            borderBottomRightRadius: '10px',
        },

        '& .spanTotal': {
            //color: unoTheme.greenMoney,
            fontSize: '14px',
        }
    },

    rightHeader: {
        display: 'flex',
        alignItems: 'center',
    },

    noHover: {
        pointerEvents: 'all !important',
        '&:hover': {
            backgroundColor: 'none !important',
        }
    },

    dpinColumn: {
        background: '#fdffe0 !important',
        borderBottom: 'solid 0.2px #efefef !important',
        '&.MuiTableCell-body': {
            '&:hover': {
                background: '#fbffc7 !important',
            }
        }

    }

}));

export default function StatusClientsScreen(props) {

    const { selectedYearClosure } = props;

    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();

    const currentUser = useSelector(state => state.currentUser)

    const [activeAdvisors, setActiveAdvisors] = useState(null);
    async function doGetActiveAdvisorsAndAdmins() {
        //const response = await getActiveAdvisorsAndAdmins();
        const response = await getActiveAdvisorsAndAdminsByConsulting(getConsultingId(currentUser));
        if (response.success) {
            //response.body.rows.unshift(TODOS_ADVISOR)
            setActiveAdvisors(response.body.rows);
        }
    }

    const [selectedAdvisor, setSelectedAdvisor] = useState(currentUser.user);
    const handleAdvisorSelectedClient = (event, newValue) => {

        setSelectedAdvisor(newValue);
    }
    const [statusClientsArray, setStatusClientsArray] = useState(null);

    const LIMIT = 30;
    const handleChange = (value) => {

        const newPage = page + value;
        setOnSnapshot(false)
        setPage(newPage);
        loadClientsClosures(newPage, selectedYearClosure, selectedAdvisor, getConsultingId(currentUser), search, selectedDairFilter);

    };

    const [search, setSearch] = useState("");

    const [page, setPage] = useState(1);
    const [totalItems, setTotalItems] = useState(1);
    async function loadClientsClosures(page,
        year,
        advisor,
        consultingId,
        search,
        dairFilter,
        filterDairMonthSelected,
        snapshotDate) {

        console.log('dairFilter: ', dairFilter);
        console.log('filterDairMonthSelected: ', filterDairMonthSelected);
        console.log('advisor: ', advisor);

        dispatch(allActions.innerLoadActions.setInnerLoad({
            show: true,
            loadMessage: 'Carregando clientes...'
        }));
        console.log('page: ', page)

        setStatusClientsArray(null);
        const offset = (page - 1) * LIMIT;
        const advisorToFilter = advisor && advisor.id ? advisor.id : null;

        const dairStatusIdFilter = dairFilter ? dairFilter.id : null;

        const responseStatusClientsArray = await doGetStatusClientsByYear(
            offset,
            advisorToFilter == null
                && selectedDairFilter == null ? LIMIT : 'NO_PAGINATION',
            year,
            advisorToFilter,
            consultingId,
            search,
            dairStatusIdFilter,
            dairStatusIdFilter ? (parseInt(filterDairMonthSelected) + 1) //pois o mês array inicia com 0
                : null,
            snapshotDate
        );

        if (responseStatusClientsArray.success) {

            if (responseStatusClientsArray.body && responseStatusClientsArray.body.length) {

                setStatusClientsArray(responseStatusClientsArray.body);
                setTotalItems(responseStatusClientsArray.totalItems);
                dispatch(allActions.innerLoadActions.setInnerLoad({
                    show: false,
                }));

            } else {
                dispatch(allActions.innerLoadActions.setInnerLoad({
                    show: true,
                    emptyMessage: "Nenhum cliente encontrado",
                }));
            }



        } else {
            setStatusClientsArray(null);

            dispatch(allActions.innerLoadActions.setInnerLoad({
                show: true,
                emptyMessage: "Nenhum cliente encontrado",
            }));
        }



    }

    //MENU
    const [openChangeStatusMenuDair, setOpenChangeStatusMenuDair] = React.useState(false);
    const [openChangeStatusMenuDpin, setOpenChangeStatusMenuDpin] = React.useState(false);
    const anchorRef = React.useRef(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const [itemsMenuChangeStatus, setItemsMenuChangeStatus] = useState(null);
    const [selectedClientInfo, setSelectedClientInfo] = useState(null);

    const [statusesDpin, setStatusesDpin] = useState(null);
    const [statusesDair, setStatusesDair] = useState(null);

    const [selectedDairFilter, setSelectedDairFilter] = useState(null);
    const [anchorElFilterDair, setAnchorElFilterDair] = useState(null);

    const [monthsFilterDair, setMonthsFilterDair] = useState(getMonths());
    const [filterDairMonthSelected, setFilterDairMonthSelected] = useState(new Date().getMonth());

    const [inputSnapshotDate, setInputSnapshotDate] = React.useState(null);
    const [onSnapshot, setOnSnapshot] = React.useState(false);

    const handleChangeInputSnapshotDate = (date) => {

        setInputSnapshotDate(date);
        if (moment(date).isValid() && date.year() == selectedYearClosure) {

            setPage(1);
            setOnSnapshot(true)
            loadClientsClosures(1,
                selectedYearClosure,
                selectedAdvisor,
                getConsultingId(currentUser),
                search, null, null, moment.utc(date).format('DD/MM/YYYY'));

        } else if (moment(date).isValid() && date.getFullYear() != selectedYearClosure) {
            dispatch(allActions.mainAlertActions.setMainAlert({
                show: true,
                message: 'O ano da data selecionada deve ser igual ao ano do filtro [' + selectedYearClosure + ']',
                type: 'warning'
            }))
        } else if (date == undefined || date == '' || date == null) {

            setPage(1);
            loadClientsClosures(1,
                selectedYearClosure,
                selectedAdvisor,
                getConsultingId(currentUser),
                search, selectedDairFilter, filterDairMonthSelected);
            setOnSnapshot(false)
        }

    };

    const handleClickFilterDair = (event) => {
        setAnchorElFilterDair(event.currentTarget);
    };

    const handleCloseFilterDair = () => {
        setAnchorElFilterDair(null);
    };

    const handleToggleDair = () => {
        setOpenChangeStatusMenuDair((prevOpen) => !prevOpen);
    };

    const handleCloseDair = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpenChangeStatusMenuDair(false);
    };

    const handleToggleDpin = () => {
        setOpenChangeStatusMenuDpin((prevOpen) => !prevOpen);
    };

    const handleCloseDpin = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpenChangeStatusMenuDpin(false);
    };

    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpenChangeStatusMenuDair(false);
            setOpenChangeStatusMenuDpin(false);
        }
    }

    async function loadStatuses() {
        const promises = [];

        promises.push(new Promise(async (resolve, reject) => {

            let response = await getStatusesDpin();

            if (response.success) {

                resolve(response.body);

            } else {

                resolve(false);

            }
        }))

        promises.push(new Promise(async (resolve, reject) => {

            let response = await getStatusesDair();

            if (response.success) {

                resolve(response.body);

            } else {

                resolve(false);

            }
        }))

        //const compositionFunds = await getCompositionFunds();

        const responseAll = await Promise.all(promises);
        console.log('responseAll: ', responseAll);
        setStatusesDpin(responseAll[0].rows);
        //setStatusesDpinMap(formatStatusesByShortName(responseAll[0].rows));
        setStatusesDair(responseAll[1].rows);
        //setStatusesDairMap(formatStatusesByShortName(responseAll[1].rows));
    }

    function openChangeStatusDair(target,
        month,
        year,
        currentStatus,
        selectedClient) {
        setSelectedClientInfo({
            client: selectedClient,
            month: month,
            year: year
        })
        handleToggleDair();
        setAnchorEl(target);
    }

    function openChangeStatusDpin(target,
        year,
        selectedClient) {

        setSelectedClientInfo({
            client: selectedClient,
            year: year
        })

        handleToggleDpin();
        setAnchorEl(target);
    }

    async function doChangeStatusDair(infoClient, statusSelected) {

        handleCloseDair();
        const response = await changeStatusDair(statusSelected.id,
            infoClient.client.id,
            currentUser.user.id,
            infoClient.month,
            infoClient.year);

        if (response.success) {


            updateViewStatusDairClient(infoClient.client.id, infoClient.month, statusSelected)
        }

    }

    async function doChangeStatusDpin(infoClient, statusSelected) {

        handleCloseDpin();
        console.log('selectedClientInfo: ', infoClient);
        console.log('statusSelected: ', statusSelected);
        const response = await changeStatusDpin(statusSelected.id,
            infoClient.client.id,
            currentUser.user.id,
            infoClient.year);

        if (response.success) {
            updateViewStatusDpinClient(infoClient.client.id, statusSelected)
        }

    }

    function renderMenuChangeStatusDair(items) {

        const menuList = [];

        items.forEach(item => {
            menuList.push(<MenuItem
                key={'menu-dairs-' + item.id}
                onClick={() => doChangeStatusDair(selectedClientInfo, item)}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <div style={{
                        backgroundColor: item.color,
                        width: '8px',
                        height: '8px',
                        marginRight: '6px',
                        borderRadius: '50%'
                    }}></div><Typography style={{ fontSize: '10px' }}>{item.short_name} - {item.name}</Typography>
                </div>
            </MenuItem >);
        });

        return menuList;
    }

    function doSelectFilterDair(itemDair) {
        setSelectedDairFilter(itemDair)
        handleCloseFilterDair();
    }

    function renderMenuFilterDair(items) {

        const menuList = [];

        menuList.push(<MenuItem
            key={'menu-remove-filter-dairs'}
            onClick={() => doSelectFilterDair(null)}>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <div style={{
                    backgroundColor: 'black',
                    width: '8px',
                    height: '8px',
                    marginRight: '6px',
                    borderRadius: '50%'
                }}></div><Typography style={{ fontSize: '10px' }}>{'REMOVER FILTRO'}</Typography>
            </div>
        </MenuItem >);
        items.forEach(item => {
            menuList.push(<MenuItem
                key={'menu-filter-dairs-' + item.id}
                onClick={() => doSelectFilterDair(item)}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <div style={{
                        backgroundColor: item.color,
                        width: '8px',
                        height: '8px',
                        marginRight: '6px',
                        borderRadius: '50%'
                    }}></div><Typography style={{ fontSize: '10px' }}>{item.short_name} - {item.name}</Typography>
                </div>
            </MenuItem >);
        });

        return menuList;
    }

    function renderMenuChangeStatusDpin(items) {

        const menuList = [];

        items.forEach(item => {
            menuList.push(<MenuItem
                key={'menu-dpin-' + item.id}
                onClick={() => doChangeStatusDpin(selectedClientInfo, item)}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <div style={{
                        backgroundColor: item.color,
                        width: '8px',
                        height: '8px',
                        marginRight: '6px',
                        borderRadius: '50%'
                    }}></div><Typography style={{ fontSize: '10px' }}>{item.short_name} - {item.name}</Typography>
                </div>
            </MenuItem >);
        });

        return menuList;
    }

    function renderColumnsStatus(clientRow) {

        //console.log('clientRow: ', clientRow);

        const columnsClient = [];

        let labelNameDpin = "";
        let labelShortNameDpin = "";
        let colorStatusDpin = "";
        if (clientRow['dpin'] == null && selectedYearClosure <= 2022) {
            const lastStatusDpin = statusesDpin[11]; //REGULAR
            labelNameDpin = lastStatusDpin.name;
            labelShortNameDpin = lastStatusDpin.short_name;
            colorStatusDpin = lastStatusDpin.color;
        } else if (clientRow['dpin'] == null && selectedYearClosure > 2022) {
            const lastStatusDpin = statusesDpin[0]; //INFORMAÇÕES PENDENTES
            labelNameDpin = lastStatusDpin.name;
            labelShortNameDpin = lastStatusDpin.short_name;
            colorStatusDpin = lastStatusDpin.color;
        } else {
            labelNameDpin = clientRow['dpin'].dpin_name;
            labelShortNameDpin = clientRow['dpin'].dpin_short_name;
            colorStatusDpin = clientRow['dpin'].dpin_color;
        }

        //const unClickAbleDpin = clientRow.dpin == STATUS_DPIN.unneeded;
        columnsClient.push(<TableCell
            key={'status-dpin-' + clientRow.client_id}
            className={classes.dpinColumn}
            onClick={(e) =>
                !onSnapshot ? openChangeStatusDpin(e.currentTarget,
                    selectedYearClosure,
                    clientRow
                ) : null}
            align="center">
            <Tooltip button
                title={labelNameDpin}
                enterDelay={200}
                leaveDelay={100}
                placement="bottom"
                arrow>
                <div style={{
                    fontWeight: '600',
                    fontSize: '10px',
                    letterSpacing: '1px',
                    color: colorStatusDpin,
                }}>{labelShortNameDpin}</div>
            </Tooltip>
        </TableCell>);

        for (let monthLoop = 1; monthLoop <= 12; monthLoop++) {

            let labelNameMonth = "";
            let labelShortNameMonth = "";
            let colorStatusMonth = "";

            if (clientRow[monthLoop] == STATUS_DAIR.unneeded) {
                labelShortNameMonth = '-'
            } else if (clientRow[monthLoop] == STATUS_DAIR.pendent) {
                const lastStatusMonth = statusesDair[0]; //EXTRATOS PENDENTES
                clientRow[monthLoop] = {
                    dair_name: lastStatusMonth.name,
                    dair_short_name: lastStatusMonth.short_name,
                    dair_color: lastStatusMonth.color
                }
                labelNameMonth = lastStatusMonth.name
                labelShortNameMonth = lastStatusMonth.short_name
                colorStatusMonth = lastStatusMonth.color
            } else {
                if (clientRow[monthLoop]) {
                    const lastStatusMonth = clientRow[monthLoop];
                    labelNameMonth = lastStatusMonth.dair_name
                    labelShortNameMonth = lastStatusMonth.dair_short_name
                    colorStatusMonth = lastStatusMonth.dair_color
                } else {
                    const lastStatusMonth = statusesDair[10]; //REGULAR DAIR
                    labelNameMonth = lastStatusMonth.name
                    labelShortNameMonth = lastStatusMonth.short_name
                    colorStatusMonth = lastStatusMonth.color
                }
            }

            const unClickAble = clientRow[monthLoop] == STATUS_DAIR.unneeded;

            columnsClient.push(
                <TableCell
                    key={'status-dair-' + clientRow.client_id + '-' + monthLoop}
                    onClick={(e) => (
                        (unClickAble || onSnapshot) ? null : openChangeStatusDair(e.currentTarget,
                            monthLoop,
                            selectedYearClosure,
                            clientRow[monthLoop],
                            clientRow
                        ))}
                    align="center" style={unClickAble ? { pointerEvents: 'none', paddingLeft: '4px', paddingRight: '4px' } : { paddingLeft: '2px', paddingRight: '2px' }}>
                    {/* {getStatusIcon(clientRow[monthLoop])} */}
                    <Tooltip button
                        title={labelNameMonth}
                        enterDelay={200}
                        leaveDelay={100}
                        placement="bottom"
                        arrow>
                        <div style={{
                            fontWeight: '600',
                            fontSize: '10px',
                            letterSpacing: '1px',
                            color: colorStatusMonth,
                        }}>{labelShortNameMonth}</div>
                    </Tooltip>
                </TableCell>
            );
        }


        return columnsClient;

    }

    function updateViewStatusDairClient(clientId, month, newStatus) {
        const newValuesStatusClientsArray = [...statusClientsArray];

        const clientToChange = newValuesStatusClientsArray.find(el => el.id == clientId);

        if (clientToChange) {

            if (!clientToChange[month]) clientToChange[month] = {}
            clientToChange[month].dair_name = newStatus.name;
            clientToChange[month].dair_short_name = newStatus.short_name;
            clientToChange[month].dair_color = newStatus.color;

            setStatusClientsArray(newValuesStatusClientsArray);
        }
    }

    function updateViewStatusDpinClient(clientId, newStatus) {
        const newValuesStatusClientsArray = [...statusClientsArray];

        const clientToChange = newValuesStatusClientsArray.find(el => el.id == clientId);

        console.log('clientToChange: ', clientToChange);

        if (clientToChange) {

            if (!clientToChange['dpin']) clientToChange['dpin'] = {}
            clientToChange['dpin'].dpin_name = newStatus.name;
            clientToChange['dpin'].dpin_short_name = newStatus.short_name;
            clientToChange['dpin'].dpin_color = newStatus.color;

            setStatusClientsArray(newValuesStatusClientsArray);
        }
    }

    async function detailClient(clientId) {

        const clientSelected = await getClientById(clientId);

        console.log('clientSelected: ', clientSelected);

        if (clientSelected.success) {
            history.push(
                {
                    pathname: path.administration.client.details.newClient,
                    state: { client: clientSelected.body }
                }
            )
        } else {
            dispatch(allActions.mainAlertActions.setMainAlert({
                show: true,
                message: "Falha ao carregar cliente",
                type: 'error',
            }));

        }

    }

    useEffect(() => {
        setPage(1);
        loadClientsClosures(1,
            selectedYearClosure,
            selectedAdvisor,
            getConsultingId(currentUser),
            search, selectedDairFilter, filterDairMonthSelected);
        setOnSnapshot(false)

    }, [selectedYearClosure, selectedAdvisor, search, selectedDairFilter, filterDairMonthSelected]);

    useEffect(() => {

        loadStatuses();
        doGetActiveAdvisorsAndAdmins();
        loadClientsClosures(1,
            selectedYearClosure,
            selectedAdvisor,
            getConsultingId(currentUser),
            search, null, null);
        setOnSnapshot(false)

    }, []);

    return (
        <div className={classes.root}>

            <div className={classes.headerSearch}>

                <Typography variant="h6" gutterBottom align="left">
                    Status dos clientes
                </Typography>

                <div className={classes.rightHeader}>

                    {
                        checkUserIsRole(currentUser, [roles.admin, roles.superadmin]) ?
                            <MyDatePicker
                                defaultValue={null}
                                style={{
                                    marginTop: '0px',
                                    marginRight: '16px',
                                    width: '160px'
                                }}
                                margin="dense"
                                format="DD/MM/YYYY"
                                value={inputSnapshotDate}
                                onChange={handleChangeInputSnapshotDate} /> : null
                    }



                    {
                        (!inputSnapshotDate || inputSnapshotDate == '' || !moment(inputSnapshotDate).isValid()) ?

                            <UnoButton
                                style={selectedDairFilter ?
                                    { marginRight: '16px', color: selectedDairFilter.color, border: 'solid 1px ' + selectedDairFilter.color }
                                    : { marginRight: '16px' }}
                                startIcon={<FilterListIcon />}
                                onClick={handleClickFilterDair}
                                type='primaryClean'>
                                {selectedDairFilter ? selectedDairFilter.short_name + ' - ' + selectedDairFilter.name : "Status DAIR"}
                            </UnoButton>
                            : null
                    }


                    {
                        selectedDairFilter && (!inputSnapshotDate || inputSnapshotDate == '' || !moment(inputSnapshotDate).isValid()) ?
                            <MySelect2
                                style={{ marginTop: '0px', marginRight: '16px' }}
                                id={'months-in-filter-dair'}
                                // disabled={innerLoad.show
                                //     && innerLoad.loadMessage == ""
                                //     && !clientOn.client.portfolio_closed}
                                value={filterDairMonthSelected}
                                onChange={(e) => setFilterDairMonthSelected(e.target.value)}
                                options={monthsFilterDair.map((row, index) => (
                                    <option key={'month-filter-dair-' + index} value={index}>
                                        {row}
                                    </option>
                                ))}
                            /> : null

                    }

                    {
                        activeAdvisors ?
                            <MyAutoComplete
                                disabled={!checkUserIsRole(currentUser, [roles.superadmin, roles.admin])}
                                options={activeAdvisors}
                                autoComplete
                                customWidth={'420px'}
                                value={
                                    selectedAdvisor ? selectedAdvisor : null
                                }
                                onChange={(event, newValue) => {
                                    console.log("New value: ", newValue)
                                    handleAdvisorSelectedClient(event, newValue)
                                }}
                                label={'Consultor'}
                                placeholder={"Selecione o consultor"}
                                getOptionLabel={(option) => option.name.toUpperCase()}
                                getOptionSelected={(option, value) => option.id === value.id}
                                renderOption={(option) => (
                                    <React.Fragment key={'autocomplete-' + option.id}>
                                        <span>{option.name.toUpperCase()}</span>
                                    </React.Fragment>
                                )}
                            /> : <Typography variant='body1' style={{ whiteSpace: 'nowrap', marginRight: '16px' }}>{selectedAdvisor && selectedAdvisor.name.toUpperCase()}</Typography>
                    }

                </div>

            </div>
            {/* <Divider /> */}
            <div class={'defaultScrollWithHeaderContent'}>

                <MyTable
                    pagination={
                        isEmpty(search) &&
                        statusClientsArray
                        && statusClientsArray.length > 0
                        && selectedAdvisor == null
                        && selectedDairFilter == null}
                    itemspage={LIMIT}
                    totalitems={totalItems}
                    currentPage={page}
                    handlePage={handleChange}
                    stickyHeader
                    withBorderColumn
                    size="small">
                    <colgroup>
                        <col width="20%" />
                    </colgroup>
                    <TableHead>
                        <TableRow>
                            <TableCell align="left"
                                style={{ paddingLeft: '0px' }}>
                                <SearchBar
                                    name='search_query'
                                    mask='text_cnpj'
                                    size='small'
                                    spacingRight
                                    variant='outlined'
                                    label='Cliente'
                                    setSearchQuery={setSearch}
                                /></TableCell>
                            <TableCell align="center" className={classes.dpinColumn}>DPIN</TableCell>
                            <TableCell align="center">Jan</TableCell>
                            <TableCell align="center">Fev</TableCell>
                            <TableCell align="center">Mar</TableCell>
                            <TableCell align="center">Abr</TableCell>
                            <TableCell align="center">Mai</TableCell>
                            <TableCell align="center">Jun</TableCell>
                            <TableCell align="center">Jul</TableCell>
                            <TableCell align="center">Ago</TableCell>
                            <TableCell align="center">Set</TableCell>
                            <TableCell align="center">Out</TableCell>
                            <TableCell align="center">Nov</TableCell>
                            <TableCell align="center">Dez</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>

                        {/* {statusClientsArray && Object.entries(statusClientsArray).map(([key, row]) => {
                            <TableRow
                                key={'portfolio-launch-client-' + index}>
                                <TableCell align="left" style={{ pointerEvents: 'none' }}>{row.label_name}</TableCell>
                                {renderColumnsStatus(row)}
                            </TableRow>
                        })} */}

                        {statusesDpin && statusesDair && statusClientsArray
                            && statusClientsArray.map((row, index) => (
                                <TableRow
                                    key={'portfolio-launch-client-' + index}>
                                    <TableCell
                                        onClick={() => detailClient(row.id)}
                                        align="left">{row.label_name}</TableCell>
                                    {renderColumnsStatus(row)}
                                </TableRow>
                            ))}
                        {/* {launchPortofolioClientsTable
                            && launchPortofolioClientsTable.map((row, index) => (
                                <TableRow
                                    onClick={() => dispatch(allActions.rightContentActions.setRightContent({
                                        path: 'clientsFromTable',
                                        data: {
                                            label: row.launch,
                                            qtd: row.qtd,
                                            clients: row.clients
                                        },
                                    }))}
                                    key={'portfolio-launch-client-' + index}>
                                    <TableCell align="left" >{formatPortfolioClosed(row.launch)}</TableCell>
                                    <TableCell align="center" >{row.qtd}</TableCell>
                                    <TableCell align="center" >{row.percent}</TableCell>
                                </TableRow>
                            ))} */}
                    </TableBody>
                    {/* {
                        totalClients ?
                            <TableFooter className={classes.myTableFooter}>

                                <TableRow className='totalLinePL' key={'footer-fechamentos-total'}>
                                    <TableCell align="left">Total clientes</TableCell>
                                    <TableCell align="center">{totalClients}</TableCell>
                                    <TableCell align="center">100%</TableCell>
                                </TableRow>
                            </TableFooter> : null
                    } */}

                </MyTable>
            </div>

            <Popper
                open={openChangeStatusMenuDair}
                anchorEl={anchorEl}
                transition
                disablePortal>
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleCloseDair}>
                                {openChangeStatusMenuDair ?
                                    <div>
                                        <Typography style={{ fontSize: '12px', padding: '8px', borderBottom: 'solid 1px #eeeeee' }}>{selectedClientInfo && (selectedClientInfo.client.label_name + ' (' + getShortMonthName(selectedClientInfo.month)
                                            + '/' + selectedClientInfo.year) + ")"}</Typography>

                                        <MenuList style={{ maxHeight: '150px', overflowY: 'scroll' }} autoFocusItem={openChangeStatusMenuDair} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                                            {renderMenuChangeStatusDair(statusesDair)}
                                        </MenuList>

                                    </div> : <div></div>
                                }
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>

            <Popper
                open={openChangeStatusMenuDpin}
                anchorEl={anchorEl}
                transition
                disablePortal>
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleCloseDpin}>
                                {openChangeStatusMenuDpin ?
                                    <div>
                                        <Typography style={{ fontSize: '12px', padding: '8px', borderBottom: 'solid 1px #eeeeee' }}>{selectedClientInfo && (selectedClientInfo.client.label_name + ' (' + selectedClientInfo.year) + ")"}</Typography>

                                        <MenuList style={{ maxHeight: '150px', overflowY: 'scroll' }} autoFocusItem={openChangeStatusMenuDpin} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                                            {renderMenuChangeStatusDpin(statusesDpin)}
                                        </MenuList>

                                    </div> : <div></div>
                                }
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>

            {statusesDair && statusesDair.length ?
                <Menu
                    id="filter-dair-menu"
                    anchorEl={anchorElFilterDair}
                    keepMounted
                    open={Boolean(anchorElFilterDair)}
                    onClose={handleCloseFilterDair}>
                    {renderMenuFilterDair(statusesDair)}
                </Menu> : null
            }

        </div >

    );
}



