import {
    getAllLocalQuotasByFundId,
    getAllLocalQuotasByFundIdMonthAndYear,
    getLocalFundInfoById,
    getLocalInfosByArrayFundsIds,
    getLocalInfosByFundIdMonthYear,
    getLocalQuotaByFundId,
    getLocalQuotasByFundMonthYear,
    saveLocalQuotas,
    updateInfosLocalQuotas,
    updateInfosLocalQuotasByFundId,
    updateLocalJsonQuotas
} from "../API/fund_local_quotas";
import {
    formatAnbimaDateToPt
} from '../components/utils/utils';

import _ from 'lodash';


/**
 * @param {Array} seriesList lista de séries obtidas da API 
 * @param {Array} date data que se refere ao mês que deve ser salva a quotização Ex: 01/01/2021 (Primeiro dia do mês)
 * @returns {obj} objFormatado
 */
export function trySaveListLocalQuotas(seriesList, date) {

    console.log("TENTANDO SALVANDO SERIES: ", seriesList);
    console.log("DATE: ", date);
    let splittedDate = date.split('/');

    let month = parseInt(splittedDate[1]);
    let year = parseInt(splittedDate[2]);

    console.log("MONTH: ", month);
    console.log("YEAR: ", year);

    seriesList.forEach(element => {
        //console.log("Iniciando save quota: ", element);
        doSaveLocalQuotas(element, date, month, year)
    });

};

/**
 * @param {Array} infosList lista de informações obtidas da API  
 * @returns {obj} objFormatado
 */
export async function trySaveListLocalInfos(infosList) {

    let responseSaveLocalInfos = await Promise.all(

        infosList.map(async obj => {

            let response = await updateInfosLocalQuotas(obj.fundLocalQuotaId, obj.infos);

            //console.log("RESPONSE LOCAL INFO: ", response);

            if (response.success) {

                return true;

            } else {

                return false;

            }

        })
    )


};

/**
 * @param {Array} infosList lista de informações obtidas da API  
 * @returns {obj} objFormatado
 */
export async function trySaveListLocalInfosByFundId(infosList, month, year) {

    console.log("SALVAR DADOS CADASTRAIS LOCALMENTE: ", infosList);
    let responseSaveLocalInfos = await Promise.all(

        infosList.map(async obj => {

            let response = await updateInfosLocalQuotasByFundId(obj.fund_id, obj.infos, month, year);

            //console.log("RESPONSE LOCAL INFO: ", response);

            if (response.success) {

                return true;

            } else {

                return false;

            }

        })
    )


};

/**
 * @param {obj} fundId id do fundo
 * @param {obj} jsonQuotas array de cotas do mês
 * @param {obj} date data to primeiro dia do mês  
 * @param {obj} month mês a ser salvo 
 * @param {obj} year ano a ser salvo 
 * @param {obj} year Se há cotas específicas para clientes ou não, caso haja deixa passar o save mesmo já havendo carteiras fechadas para essas cotas
 * @returns {obj} objFormatado
 */
export async function doSaveOrUpdateLocalQuotas(fundId, jsonQuotas, date, month, year, withClientsQuota) {

    let fundLocalQuotas = {
        fund_id: fundId,
        json_quotas: JSON.stringify(jsonQuotas),
        month: month,
        year: year,
        date_quotas: date,
        withClientsQuota: withClientsQuota
    }

    let response = await updateLocalJsonQuotas(fundLocalQuotas);

    if (response.success) {
        //console.log("RESPONSE SAVE LOCAL: ", response.body);
        return {
            success: true,
        }
    } else {
        return {
            success: false,
            body: response.body
        }
    }
};

/**
 * @param {obj} serie objeto de serie, contém as séries, o código_anbima e o fund_id
 * @param {obj} month mês a ser salvo 
 * @param {obj} year ano a ser salvo 
 * @returns {obj} objFormatado
 */
export async function doSaveLocalQuotas(serie, date, month, year) {

    let fundLocalQuotas = {
        fund_id: serie.fund_id,
        //anbima_cod: serie.anbimaCod,
        json_quotas: JSON.stringify(serie.serie_historica),
        month: month,
        year: year,
        date_quotas: date,
    }

    let response = await saveLocalQuotas(fundLocalQuotas);

    if (response.success) {
        //console.log("RESPONSE SAVE LOCAL: ", response.body);
        return {
            success: true,
        }
    } else {
        return {
            success: false,
        }
    }
};


/**
 * @param {obj} cods códigos anbima e fund_ids
 * @param {obj} date  
 * @param {obj} mainKey para saber qual a chave deve ser utilizada pelo mapa de cotas  
 * @param {Integer} clientId a fim de trazer as possíveis múltiplas cotas dos fundos especiais
 * @returns {obj} objFormatado
 */
export async function getLocalQuotasByListFunds(filteredQuotas, cods, date, mainKey, clientId) {

    let historicSeries = await Promise.all(
        cods.map(async obj => {

            //console.log("CHAMANDO OBJ: ", obj);
            let serie = await getLocalQuotaByFundId(obj.fund_id, date);

            console.log("RESPONSE LOCAL SERIE: ", serie);

            if (serie.success && serie.body && serie.body.rows.length == 1) {
                return {
                    //anbimaCod: obj.anbima_cod,
                    anbima_cod: obj.anbima_cod,
                    fund_id: obj.fund_id,
                    //serie_historica: JSON.parse(serie.body.rows[0].json_quotas),
                    //NEW VALUE QUOTA
                    serie_historica: getClientJsonQuotas(JSON.parse(serie.body.rows[0].json_quotas), clientId),
                };
            } else {

                return serie.body;

            }

        })
    )

    console.log("LOCAL SERIES: ", historicSeries);
    console.log("mainKey: ", mainKey);

    if (Array.isArray(historicSeries)) {

        historicSeries.forEach(element => {

            if (element && element.serie_historica) {

                filteredQuotas[element[mainKey]] = element.serie_historica;

            }

        });
    }

    return filteredQuotas;

}

/**
 * @param {obj} assets ativos a receberem as informações
 * @param {obj} fundLocalQuotaCods códigos de informações locais 
 * @returns {obj} objFormatado
 */
export async function getLocalInfosByListFunds(assets, fundLocalQuotaCods) {

    let localInfos = await Promise.all(
        fundLocalQuotaCods.map(async localFundoQuotaCod => {
            let info = await getLocalFundInfoById(localFundoQuotaCod);

            //console.log("RESPONSE LOCAL INFO: ", info);

            if (info.success && info.body && info.body.rows.length == 1) {
                return {
                    fund_id: info.body.rows[0].fund_id,
                    infos: JSON.parse(info.body.rows[0].json_fund_infos),
                };
            } else {

                return info.body;

            }

        })
    )

    console.log("LOCAL INFOS: ", localInfos);
    //console.log("ASSETS: ", assets);

    let mapInfos = {};
    if (Array.isArray(localInfos)) {

        localInfos.forEach(element => {

            mapInfos[element.fund_id] = element.infos;

        });
    }

    console.log("MAP INFOS: ", mapInfos);


    let newAssets = [];
    assets.forEach(asset => {

        //Caso haja informações localmente se colocam as informações nos assets;        
        if (mapInfos[asset.fund_id]) {

            //console.log("INSERINDO DADOS VINDO DE LOCAL: ", mapInfos[asset.fund_id]);

            asset = {
                ...asset,
                ...mapInfos[asset.fund_id],
            }

            newAssets.push(asset);

        } else {

            newAssets.push(asset);

        }

    })

    console.log("NEW ASSETS AFTER INFOS: ", newAssets);

    return newAssets;

}

export async function fillLocalInfosByMonthAndYear(assets, month, year) {

    const responseLocalInfos = await getLocalInfosByArrayFundsIds(assets.map((el) => {
        return el.fund_id
    }), month, year);

    console.log('responseLocalInfos: ', responseLocalInfos);

    if (responseLocalInfos.success) {
        //do map
        const mapInfo = {};
        responseLocalInfos.body.rows.forEach(info => {

            if (!mapInfo[info.fund_id]) {

                mapInfo[info.fund_id] = info.json_fund_infos ? JSON.parse(info.json_fund_infos) : null;
            }
        });
        console.log('mapInfo: ', mapInfo)

        const assetsWithInfos = [];
        assets.forEach(asset => {

            assetsWithInfos.push({
                ...asset,
                ...mapInfo[asset.fund_id]
            })

        });

        console.log('assetsWithInfos: ', assetsWithInfos);
        return assetsWithInfos;
    }

    return assets;

}

export async function doGetAllLocalQuotasByFundId(fundId) {

    let responseLocalQuotas = await getAllLocalQuotasByFundId(fundId);

    if (responseLocalQuotas.success) {

        return {
            success: true,
            body: responseLocalQuotas.body,
        }

    } else {

        return {
            success: false,
            body: "Falha ao obter as cotas locais do fundo",
        }
    }

}

export async function doGetAllLocalQuotasByFundIdMonthAndYear(fundId, month, year) {

    let responseLocalQuotas = await getAllLocalQuotasByFundIdMonthAndYear(fundId, month, year);

    if (responseLocalQuotas.success) {

        return {
            success: true,
            body: responseLocalQuotas.body,
        }

    } else {

        return {
            success: false,
            body: "Falha ao obter as cotas locais do fundo",
        }
    }

}

export async function doGetLocalInfosByFundIdMonthYear(fundId, month, year) {

    let response = await getLocalInfosByFundIdMonthYear(fundId, month, year);

    if (response.success) {

        return {
            success: true,
            body: response.body,
        }

    } else {

        return {
            success: false,
            body: "Falha ao obter as cotas locais do fundo",
        }
    }

}

export function formatFundLocalQuotasMonthYear(allFundQuotas) {

    let formattedQuotas = {
        years: [],
        yearsWithMonths: {},
        data: {},
        dataInfos: {},
    };

    allFundQuotas.forEach(fundQuotas => {

        if (!formattedQuotas.yearsWithMonths[fundQuotas.year]) {

            formattedQuotas.years.push(fundQuotas.year);
            //formattedQuotas[fundQuotas.year] = [];
            formattedQuotas.yearsWithMonths[fundQuotas.year] = [];

        }

        formattedQuotas.yearsWithMonths[fundQuotas.year].push(fundQuotas.month);

        formattedQuotas.data[fundQuotas.month + "/" + fundQuotas.year] = JSON.parse(fundQuotas.json_quotas);
        formattedQuotas.dataInfos[fundQuotas.month + "/" + fundQuotas.year] = fundQuotas.json_fund_infos ? JSON.parse(fundQuotas.json_fund_infos) : null;
    });

    return formattedQuotas;

}


export async function doCheckHasCotaDayFund(fundId, day) {

    const splittedDate = day.split('/');
    const month = parseInt(splittedDate[1]);
    const year = parseInt(splittedDate[2]);

    const responsejsonQuotas = await getLocalQuotasByFundMonthYear(fundId, month, year);
    console.log('JSON QUOTAS: ', responsejsonQuotas);
    if (responsejsonQuotas.success) {

        if (responsejsonQuotas.body.rows.length == 0) {
            return false;
        }

        const cotas = JSON.parse(responsejsonQuotas.body.rows[0].json_quotas);
        if (cotas.some(el => formatAnbimaDateToPt(el.data_referencia) == day)) {
            return true;
        } else {
            return false;
        }
    } else {
        return false;
    }

}

export function getClientJsonQuotas(jsonQuotas, clientId) {

    jsonQuotas.forEach(quota => {

        quota = getValueQuota(quota, clientId);

    });

    return jsonQuotas;

}

export async function getValueQuota(quota, clientId) {

    if (clientId && quota['valor_cota_' + clientId]) {

        //console.log("ENTROU COTA CLIENTE: ", _.cloneDeep(quota));

        quota.valor_cota_default = quota.valor_cota;
        quota.valor_cota = quota['valor_cota_' + clientId];

        //console.log("SAIU COTA CLIENTE: ", _.cloneDeep(quota));

        return quota;
    } else {
        return quota;
    }
}

