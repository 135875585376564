import moment from 'moment';

import {
    applyMaskAmount,
    formatPtStringDateToAnbimaDate,
    getAllPeriods,
    getDayMonthYearByStringDate,
    getFirstDayMonth,
    getMonthAgo,
    getMonthAndYearBefore,
    getPeriodBefore,
    getTitleCod,
    getTotalApplicationsAndRescuesByTransactions,
    stringPtDateToMoment
} from '../components/utils/utils';

import {
    saveTitleClientRent
} from "../API/client";

import { BalancesValidationsAPI } from '../API/balances_validations';
import {
    getClientAllAssetsTitleByDateAndRegimeWithInits,
    saveCoupon
} from "../API/title";
import { sumTitlesTransactionsInMonthByPeriod, sumTitlesTransactionsInPeriodByTitleAssetIdAndOperation } from '../API/transaction';
import { getSimpleRentMoneyTitle, getSimpleRentMoneyTitle2, getSimpleRentTitle, getSimpleRentTitle2, groupTitleSumTransactions } from './ReportController';

export const PUBLIC_TITLES = "TÍTULOS PÚBLICOS";
export const PRIVATE_TITLES = "TÍTULOS PRIVADOS";
export const GENERIC_ASSETS = "IMÓVEIS";
export const GENERIC_ASSET_TITLES_TYPE = "ATIVO GENÉRICO";

export const OUT_OF_MARKET_RENT_TITLE = 5; //%

export const TITLES_OPERATIONS_METHODS = {

    PUBLIC: 'operationRentDayADayNew', //NTNB, NTNF, NTNC, LFT, LTN
    PRIVATE_PRE_FIXED: 'operationPreFixedTitle', //CDB, LF
    PRIVATE_CDI: 'operationRentCDITitle', //CDB 
    PRIVATE_IPCA: 'operationLFTitle', //CDB, CVSB, LF 
    GENERIC: 'operationGenericTitle', //imóveis
}

function calcMonthTax(assetTitle) {

    if (assetTitle.title_local_quotas && assetTitle.title_local_quotas.length > 0) {

        const businessDays = assetTitle.title_local_quotas.length;
        return Math.pow(assetTitle.pre_tax + 1, businessDays / 252)

    } else {
        return 1;
    }
}

function calcMonthIpcaDp(assetTitle) {

    if (!assetTitle.ipcadp_local || !assetTitle.ipcadp_local_before || assetTitle.ipcadp_local.length == 0 || assetTitle.ipcadp_local_before.length == 0) {
        return null
    }

    return assetTitle.ipcadp_local[assetTitle.ipcadp_local.length - 1].ipca_dp / assetTitle.ipcadp_local_before[assetTitle.ipcadp_local_before.length - 1].ipca_dp
}

function calcRentTitle(monthTax, monthIpcaDp, title) {

    if (title.balance_before != null && title.balance_before !== 0) {

        return (monthTax * (1 + monthIpcaDp)) * title.balance_before;

    } else if (title.balance_before === 0) { //Caso em que o saldo anterior é zero devido o mês atual ter sido o mês da compra

        let purchasedTax = null;
        if (title.title_local_quotas && title.title_local_quotas.length > 0) {

            purchasedTax = title.title_local_quotas.find(el => el.date_quota == moment.utc(title.purchase_date).format('DD/MM/YYYY'));

        }
        //Multplicar pelo saldo do dia da compra
        const balancePurchasedDate = title.balance_now;

        //Saldo da primeira movimentação
        return (monthTax * (1 + monthIpcaDp)) * balancePurchasedDate;
    }

}

function operationRentGenericTitle(title) {

    title.aOperationMethod = TITLES_OPERATIONS_METHODS.GENERIC;
    title.pl = title.balance_now;
    title.qtd_quota = title.quota_amount_now;
}

function getCdbQuotaValue(title, dailyCdiValue) {

    if (title.pl !== 0) {

        let currentQuotaValue = null;
        if (title.quota_amount_before == 0 && title.balance_before == 0 && !title.cdb_quota_value) {
            currentQuotaValue = title.price_title;
        } else {
            if (!title.cdb_quota_value) {
                currentQuotaValue = title.balance_before / title.quota_amount_before;
            } else {
                currentQuotaValue = title.cdb_quota_value;
            }
        }

        title.cdb_quota_value = currentQuotaValue * dailyCdiValue;

        return title.cdb_quota_value;

    } else {

        return undefined;

    }
}

function operationRentCDITitle(title) {

    title.aOperationMethod = TITLES_OPERATIONS_METHODS.PRIVATE_CDI;
    if (!title.cdi_month) return;
    title.cdi_month.forEach((dailyCdi, index) => {

        let applicationsAmount = 0.0;
        let rescuesAmount = 0.0;
        let amortizationAmount = 0.0;

        const dateQuota = dailyCdi.date;

        title.pl_pre_app_rescues = title.pl;
        //title.cdi_month
        const dailyCdiValue = ((dailyCdi.value / 100) * title.indexer_percent) + 1;
        console.log('dailyCdiValue [' + dailyCdi.date + ']: ', dailyCdiValue);

        title.pl *= dailyCdiValue;

        const diaryQuotaValue = getCdbQuotaValue(title, dailyCdiValue);

        if (title.balance_before == 0
            && dailyCdi.date == moment.utc(title.purchase_date).format('DD/MM/YYYY')
            && title.transactions && title.transactions.length) {

            if (title.transactions && title.transactions[0] && title.transactions.length == 1) {
                title.transactions[0].result_qtd_quotas = parseFloat(title.quantity_purchased);
            }

            title.pl = parseFloat(title.transactions[0].amount);
            title.qtd_quota = parseFloat(title.quantity_purchased);
            applicationsAmount = parseFloat(title.transactions[0].amount);

        } else {
            //Verificando aplicações e resgates
            if (title.transactions) {

                const applicationsTransactions = title.transactions.filter(
                    el =>
                        el.operation_id == 1
                        && moment.utc(el.transaction_date).format('DD/MM/YYYY') == dateQuota
                );


                console.log('dateQuota: ', dateQuota);
                console.log('applicationsTransactions: ', applicationsTransactions);
                if (applicationsTransactions && applicationsTransactions.length > 0) {
                    console.log('applicationsTransactions: ', applicationsTransactions);
                    const sumApplications = applicationsTransactions.reduce(
                        (previous, current) => previous + parseFloat(current.amount),
                        0
                    )
                    applicationsAmount += sumApplications;

                    if (title.pl == 0) {
                        title.qtd_quota += title.quota_amount_now;
                    } else {

                        // const proportionQtdQuotas = sumApplications / title.pl;
                        // title.qtd_quota += parseFloat(title.qtd_quota) * proportionQtdQuotas;
                        const applicationsQuotas = sumApplications / diaryQuotaValue;
                        title.qtd_quota += applicationsQuotas;

                    }

                    title.pl += sumApplications;


                }

                const rescuesTransactions = title.transactions.filter(
                    el =>
                        el.operation_id == 2
                        && moment.utc(el.transaction_date).format('DD/MM/YYYY') == dateQuota
                );
                if (rescuesTransactions && rescuesTransactions.length > 0) {
                    console.log('rescuesTransactions: ', rescuesTransactions);
                    const sumRescues = rescuesTransactions.reduce(
                        (previous, current) => previous + parseFloat(current.amount),
                        0
                    )
                    rescuesAmount += sumRescues;

                    const rescuesQuotas = sumRescues / diaryQuotaValue;
                    console.log('rescuesQuotas: ', rescuesQuotas)
                    title.qtd_quota -= rescuesQuotas;
                    if (title.qtd_quota < 0) {
                        title.qtd_quota = 0;
                    }
                    console.log('NEWWW title.qtd_quota: ', title.qtd_quota);


                    title.pl -= sumRescues;
                    if (title.pl < 0) {
                        title.pl = 0;
                    }

                    rescuesTransactions.forEach((el) => {
                        el.quota_value_transaction = parseFloat(el.amount) / diaryQuotaValue;
                    })


                }

            }
        }


        title.diaryPortfolioPl[formatPtStringDateToAnbimaDate(dateQuota)] = {

            pl: title.pl_pre_app_rescues,
            newPl: title.pl,
            result_app_rescues: applicationsAmount - rescuesAmount,
            qtd_quotas_day: title.qtd_quota,
            quota_value_day: diaryQuotaValue,

        };


    });


}

function operationRentCDITitleOLD(title) {

    title.aOperationMethod = 'operationRentCDITitleOLD';
    console.log("TITLE CDI CALC");
    title.cdi_month.forEach((dailyCdi, index) => {

        let applicationsAmount = 0.0;
        let rescuesAmount = 0.0;
        let amortizationAmount = 0.0;

        const dateQuota = dailyCdi.date;

        title.pl_pre_app_rescues = title.pl;
        //title.cdi_month
        const dailyCdiValue = ((dailyCdi.value / 100) * title.indexer_percent) + 1;
        //console.log('dailyCdiValue [' + dailyCdi.date + ']: ', dailyCdiValue);

        title.pl *= dailyCdiValue;

        if (title.balance_before == 0
            && dailyCdi.date == moment.utc(title.purchase_date).format('DD/MM/YYYY')
            && title.transactions && title.transactions.length) {

            if (title.transactions && title.transactions[0] && title.transactions.length == 1) {
                title.transactions[0].result_qtd_quotas = parseFloat(title.quantity_purchased);
            }

            title.pl = parseFloat(title.transactions[0].amount);
            title.qtd_quota = parseFloat(title.quantity_purchased);
            applicationsAmount = parseFloat(title.transactions[0].amount);

        } else {
            //Verificando aplicações e resgates
            if (title.transactions) {

                const applicationsTransactions = title.transactions.filter(
                    el =>
                        el.operation_id == 1
                        && moment.utc(el.transaction_date).format('DD/MM/YYYY') == dateQuota
                );


                console.log('dateQuota: ', dateQuota);
                console.log('applicationsTransactions: ', applicationsTransactions);
                if (applicationsTransactions && applicationsTransactions.length > 0) {
                    console.log('applicationsTransactions: ', applicationsTransactions);
                    const sumApplications = applicationsTransactions.reduce(
                        (previous, current) => previous + parseFloat(current.amount),
                        0
                    )
                    applicationsAmount += sumApplications;

                    if (title.pl == 0) {
                        title.qtd_quota += title.quota_amount_now;
                    } else {
                        const proportionQtdQuotas = sumApplications / title.pl;
                        title.qtd_quota += parseFloat(title.qtd_quota) * proportionQtdQuotas;

                    }

                    title.pl += sumApplications;


                }

                const rescuesTransactions = title.transactions.filter(
                    el =>
                        el.operation_id == 2
                        && moment.utc(el.transaction_date).format('DD/MM/YYYY') == dateQuota
                );
                if (rescuesTransactions && rescuesTransactions.length > 0) {
                    console.log('rescuesTransactions: ', rescuesTransactions);
                    const sumRescues = rescuesTransactions.reduce(
                        (previous, current) => previous + parseFloat(current.amount),
                        0
                    )
                    rescuesAmount += sumRescues;

                    const proportionQtdQuotas = title.pl != 0 ? (sumRescues / title.pl) : 1;
                    console.log('sumRescues: ', sumRescues);
                    console.log('title.pl: ', title.pl);
                    console.log('proportionQtdQuotas: ', proportionQtdQuotas);
                    console.log('title.qtd_quota: ', title.qtd_quota);
                    console.log('title.qtd_quota rescue: ', parseFloat(title.qtd_quota) * proportionQtdQuotas);
                    console.log('XXXXX: ', title.qtd_quota + " - " + (parseFloat(title.qtd_quota) * proportionQtdQuotas) + " = " + (title.qtd_quota - parseFloat(title.qtd_quota) * proportionQtdQuotas));
                    title.qtd_quota -= parseFloat(title.qtd_quota) * proportionQtdQuotas;
                    console.log('NEWWW title.qtd_quota: ', title.qtd_quota);


                    title.pl -= sumRescues;


                }

            }
        }

        console.log('CDB PL: ', title.pl);

        title.diaryPortfolioPl[formatPtStringDateToAnbimaDate(dateQuota)] = {

            pl: title.pl_pre_app_rescues,
            newPl: title.pl,
            result_app_rescues: applicationsAmount - rescuesAmount,

        };
    });

}

function operationPreFixedTitle(title) {

    title.aOperationMethod = TITLES_OPERATIONS_METHODS.PRIVATE_PRE_FIXED;

    if (!title.cdi_month) return;

    title.cdi_month.forEach((tlq, index) => {

        title.pl_pre_app_rescues = title.pl;

        const dateQuota = tlq.date;

        //Utilizado para fazer a rentabilidade do fundo na carteira
        let resultTransactionAmount = 0.0;
        let applicationsAmount = 0.0;
        let rescuesAmount = 0.0;
        let amortizationAmount = 0.0;


        const dailyRent = (Math.pow(1 + (title.pre_tax ? (parseFloat(title.pre_tax) / 100) : 0), 1 / 252));
        console.log("DAYLY RENT PRE FIXED: ", dailyRent);

        //title.rent[dateQuota] = title.pl * (dailyRent - 1);

        title.pl *= dailyRent;

        if (title.balance_before == 0) {

            if (title.transactions
                && title.transactions[0]
                && title.transactions.length == 1
                && dateQuota == moment.utc(title.transactions[0].transaction_date).format('DD/MM/YYYY')) {


                console.log("ENTROU CDI PRE FIXADO");
                title.transactions[0].result_qtd_quotas = parseFloat(title.quantity_purchased);
                title.pl = title.quantity_purchased * title.price_title;
                title.qtd_quota = title.quantity_purchased;
                resultTransactionAmount = title.pl;
            }

        } else {
            //Verificando resgates
            if (title.transactions) {

                const applicationsTransactions = title.transactions.filter(
                    el =>
                        el.operation_id == 1
                        && moment.utc(el.transaction_date).format('DD/MM/YYYY') == dateQuota
                );
                if (applicationsTransactions && applicationsTransactions.length > 0) {
                    console.log('applicationsTransactions: ', applicationsTransactions);
                    const sumApplications = applicationsTransactions.reduce(
                        (previous, current) => previous + parseFloat(current.amount),
                        0
                    )
                    applicationsAmount += sumApplications;

                    console.log('sumApplications: ', sumApplications);
                    console.log('sumApplications: ', sumApplications);

                    if (title.pl == 0) {
                        title.qtd_quota += title.quota_amount_now;
                    } else {
                        const proportionQtdQuotas = sumApplications / title.pl;
                        title.qtd_quota += parseFloat(title.qtd_quota) * proportionQtdQuotas;
                    }

                    title.pl += sumApplications;
                    resultTransactionAmount += sumApplications;


                }

                const rescuesTransactions = title.transactions.filter(
                    el =>
                        el.operation_id == 2
                        && moment.utc(el.transaction_date).format('DD/MM/YYYY') == dateQuota
                );
                if (rescuesTransactions && rescuesTransactions.length > 0) {
                    console.log('rescuesTransactions: ', rescuesTransactions);
                    const sumRescues = rescuesTransactions.reduce(
                        (previous, current) => previous + parseFloat(current.amount),
                        0
                    )
                    rescuesAmount += sumRescues;

                    const proportionQtdQuotas = title.pl != 0 ? (sumRescues / title.pl) : 1;

                    title.qtd_quota -= parseFloat(title.qtd_quota) * proportionQtdQuotas;
                    title.pl -= sumRescues;

                    resultTransactionAmount -= sumRescues;
                }

            }

        }

        //Caso tenha amortização
        title.pl -= amortizationAmount;

        title.diaryPortfolioPl[formatPtStringDateToAnbimaDate(dateQuota)] = {

            pl: title.pl_pre_app_rescues,
            newPl: title.pl,
            result_app_rescues: resultTransactionAmount,

        };

        console.log('PL: ', title.pl);
    });

    console.log('title.rents: ', title.rents);
    console.log('title.diaryPortfolioPl:', title.diaryPortfolioPl);

}

function operationRentDayADay(title) {

    title.title_local_quotas.forEach((tlq, index) => {

        title.pl_pre_app_rescues = title.pl;

        const dateQuota = tlq.date_quota;
        const valueQuota = tlq.pu_anb;

        //Utilizado para fazer a rentabilidade do fundo na carteira
        let resultTransactionAmount = 0.0;
        let applicationsAmount = 0.0;
        let rescuesAmount = 0.0;
        let amortizationAmount = 0.0;

        if (title.transactions) {


            const listTransactionsInDay = title.transactions.filter(el => moment.utc(el.transaction_date).format('DD/MM/YYYY') == dateQuota);

            if (listTransactionsInDay) {

                listTransactionsInDay.forEach(transactionLoop => {

                    if (transactionLoop.operation_id == 1) { //Aplicação

                        resultTransactionAmount += parseFloat(transactionLoop.amount);

                        //Obtendo quantidade de quotas da movimentação
                        console.log('valueQuota Expired: ', valueQuota);
                        transactionLoop.result_qtd_quotas = transactionLoop.amount / valueQuota;
                        title.sumQuotasApplication += transactionLoop.result_qtd_quotas;

                        applicationsAmount += parseFloat(transactionLoop.amount);

                    } else if (transactionLoop.operation_id == 2) {//Resgate

                        console.log('valueQuota Expired: ', valueQuota);
                        resultTransactionAmount -= parseFloat(transactionLoop.amount);

                        //Obtendo quantidade de quotas da movimentação
                        transactionLoop.result_qtd_quotas = transactionLoop.amount / valueQuota;
                        title.sumQuotasRescues += transactionLoop.result_qtd_quotas;

                        rescuesAmount += parseFloat(transactionLoop.amount);

                    } else if (transactionLoop.operation_id == 3) {//Amortização

                        console.log('valueQuota Expired: ', valueQuota);
                        //Amortização não é considerada para o saldo e nem para a rentabilidade
                        amortizationAmount += parseFloat(transactionLoop.amount);
                    }
                });

                //title.qtd_quota += (resultTransactionAmount / valueQuota);
            }
        }


        //title.pl = title.qtd_quota * valueQuota;

        const ipcaDpLocalNow = title.ipcadp_local;
        const ipcaDpLocalBefore = title.ipcadp_local_before;

        //Caso em que não houver indexação retirar obrigatoriedade de dailyIpca, mantendo apenas a taxa pré

        if (ipcaDpLocalNow && ipcaDpLocalNow[index] && ipcaDpLocalBefore && ipcaDpLocalBefore[ipcaDpLocalBefore.length - 1]) {
            const dailyIpca = ipcaDpLocalNow[index].ipca_dp / (index == 0 ?
                ipcaDpLocalBefore[ipcaDpLocalBefore.length - 1].ipca_dp
                :
                ipcaDpLocalNow[index - 1].ipca_dp);


            const dailyRent = dailyIpca * (Math.pow(1 + (title.pre_tax ? (parseFloat(title.pre_tax) / 100) : 0), 1 / 252));

            title.rent[tlq.date_quota] = title.pl * (dailyRent - 1);

            // console.log('title.pre_tax: ', title.pre_tax);
            // console.log('tlq.date_quota: ', tlq.date_quota);
            // console.log('moment: ', moment.utc(title.purchase_date).format('DD/MM/YYYY'));
            // console.log('title.balance_before : ', title.balance_before);

            // console.log('dailyIpca: ', dailyIpca);
            // console.log('PL x DAYLY RENT: ', title.pl + ' x ' + (dailyRent - 1) + ' = ' + title.pl * (dailyRent - 1));
            title.pl *= dailyRent;

            if (title.balance_before == 0 && tlq.date_quota == moment.utc(title.purchase_date).format('DD/MM/YYYY')) {
                console.log("ENTROU AQUI::::");

                if (title.transactions && title.transactions[0] && title.transactions.length == 1) {
                    title.transactions[0].result_qtd_quotas = parseFloat(title.quantity_purchased);
                }

                title.pl = resultTransactionAmount;
                title.qtd_quota = title.quantity_purchased;

            }


            //Caso tenha amortização
            title.pl -= amortizationAmount;

            title.diaryPortfolioPl[formatPtStringDateToAnbimaDate(dateQuota)] = {

                pl: title.pl_pre_app_rescues, //PL do dia após os rendimentos a partir das atualizações de quotas do fundo
                newPl: title.pl,
                result_app_rescues: resultTransactionAmount,

            };
        } else {
            console.error('Não existem IPCAS DPS')
        }



        console.log('PL: ', title.pl);
    });

    console.log('title.rents: ', title.rents);
    console.log('title.diaryPortfolioPl:', title.diaryPortfolioPl);
}

const getPuPUBLIC = (isExpiredMonth, cuponsMonthAmounts, title, isStrangeExpiredMonth) => {

    if ((isExpiredMonth || isStrangeExpiredMonth)
        && title.transactions
        && title.transactions.length) {

        return parseFloat(title.transactions[0].amount) / parseFloat(title.transactions[0].quota_value_transaction)

    } else if (cuponsMonthAmounts != null) {


        if (title.balance_now === 0 && title.quota_amount_now == 0) return 0;
        return (title.balance_now + cuponsMonthAmounts) / title.quota_amount_now

    } else {

        if (title.balance_now === 0 && title.quota_amount_now == 0) return 0;
        return title.balance_now / title.quota_amount_now

    }


}

function operationRentDayADayNew(title) {

    title.aOperationMethod = TITLES_OPERATIONS_METHODS.PUBLIC;

    const cuponsMonthAmounts = title.historicAmortizations ?
        title.historicAmortizations.reduce(
            (previous, current) => previous + parseFloat(current.amount),
            0
        )
        : null;

    let businessDaysAfterApplication = 0;
    let addBusinessDays = false;
    let newPlAdded = false;

    const amortizationMap = {};
    let allAmortizationAmount = 0;
    if (title.title_local_quotas) {

        const startBussinessDay = title.title_local_quotas[0].date_quota
        const endBussinessDay = title.title_local_quotas[title.title_local_quotas.length - 1].date_quota

        const isAllBusinessDays = !title.transactions ||
            (title.transactions && title.transactions.length > 0
                && (title.transactions.filter(el => el.operation_id == 3).length == title.transactions.length))
            ||
            //ADICIONADO DEVIDO LETRA FINANCEIRA COM RESGATE PARCIAL
            (
                (title.marking == "MERCADO" || title.label_title_type == "LFT")
                && title.transactions
                && title.transactions.length > 0
                && title.quota_amount_before
            )


        const momentDueDate = moment.utc(title.due_date);
        const momentStartBusinessDay = stringPtDateToMoment(startBussinessDay, true)
        const momentEndBusinessDay = stringPtDateToMoment(endBussinessDay, true)

        //Mês de vencimento do título
        const isExpiredMonth = (momentDueDate.isSameOrAfter(momentStartBusinessDay)
            && momentDueDate.isSameOrBefore(momentEndBusinessDay))
            || momentDueDate.isBefore(startBussinessDay)

        const isStrangeExpiredMonth = momentDueDate.isBefore(startBussinessDay);

        title.isStrangeExpiredMonth = isStrangeExpiredMonth;

        //Resgate antecipado
        const isMarketMultiplePuTitle =
            (
                (title.marking == "MERCADO" || title.label_title_type == "LFT")
                && title.transactions
                && title.transactions.length
            )

        //&& (title.transactions.filter(el => el.operation_id == 3).length == 0)

        if (isMarketMultiplePuTitle) {

            title.isMarketMultiplePuTitle = true
        }

        //Em caso de vencimento do título, ocorre o lançamento e o PU é calculado de forma diferente
        //const pu = getPuPUBLIC(isExpiredMonth, cuponsMonthAmounts, title, isStrangeExpiredMonth);
        //@@@ RETIRANDO CUPONS CASO IRATI E SAO MARCOS (SETE) EM RENTABILIDADES DE TITULOS COM AMORTIZACAO
        //const pu = getPuPUBLIC(isExpiredMonth, cuponsMonthAmounts, title, isStrangeExpiredMonth);
        const pu = getPuPUBLIC(isExpiredMonth, 0, title, isStrangeExpiredMonth);

        title.pu = pu;
        title.cuponsMonthAmounts = cuponsMonthAmounts;


        const multiplePUs = {};
        if (isMarketMultiplePuTitle) {

            console.log('title.quota_amount_before: ', title.quota_amount_before);
            multiplePUs['initPu'] = {
                puValue: title.quota_amount_before ? title.balance_before / title.quota_amount_before : null,
                days: [],
            }
            console.log('multiplePUs["initPu"]: ', multiplePUs['initPu'].puValue);
            title.transactions.filter(el => el.operation_id != 3).forEach(transactionLoop => {

                if (!multiplePUs['datesMultiplePUs']) {
                    multiplePUs['datesMultiplePUs'] = [];
                }
                const date = moment.utc(transactionLoop.transaction_date).format('YYYY-MM-DD');
                multiplePUs.datesMultiplePUs.push(date);
                multiplePUs[date] = {
                    puValue: parseFloat(transactionLoop.amount) / parseFloat(transactionLoop.quota_value_transaction),
                    days: [],
                }

            });

            let finalPu = null;
            if (title.quota_amount_now) { //existe rentabilização até o fim do mês
                finalPu = pu;
            } else {


                finalPu = multiplePUs.datesMultiplePUs
                    && multiplePUs.datesMultiplePUs.length
                    && multiplePUs[multiplePUs.datesMultiplePUs.length - 1] ? multiplePUs[multiplePUs.datesMultiplePUs.length - 1].puValue : null;

            }
            multiplePUs['finalPu'] = {
                puValue: finalPu,
                days: [],
            }

        }

        title.multiplePUs = multiplePUs;

        title.puInit = pu;

        if (
            isExpiredMonth
            && title.transactions
            && title.transactions.length) {

            title.isExpiredMonth = true;
        }


        let monthRent = 0;
        //Indica que é o mês da primeira aplicação
        if (title.transactions
            && (
                (title.transactions.length == 1
                    && title.transactions[0].operation_id != 3)
                ||
                (title.transactions.length == 2 &&
                    title.transactions[0].operation_id == 1 && title.transactions[1].operation_id == 3)
            )
            && !isExpiredMonth && !isMarketMultiplePuTitle) {
            title.monthRentModel = 1;
            monthRent = (pu / title.price_title) - 1;
        } else {
            title.monthRentModel = 2;
            const puBefore = title.balance_before / title.quota_amount_before;
            monthRent = (pu / puBefore) - 1;
        }

        title.monthRent = monthRent;

        const resultAppRescuesByDate = {}
        const amortizationsByDate = {}
        let multiplePUsDate = null //Data que está sendo atualmente considerada para a contagem de dias para cálculo do monthRent
        // if (isExpiredMonth) {
        //     title.title_local_quotas[9].pu_anb = title.title_local_quotas[8].pu_anb;
        // }
        //title.title_local_quotas[8];
        let amortizationAmount = 0.0;
        title.title_local_quotas.forEach((tlq, index) => {

            const dateQuota = tlq.date_quota;
            const valueQuota = tlq.pu_anb;

            const anbimaDateQuota = formatPtStringDateToAnbimaDate(dateQuota);


            if (isMarketMultiplePuTitle) {

                if (!multiplePUs[anbimaDateQuota] && multiplePUsDate == null) {

                    multiplePUs['initPu'].days.push(anbimaDateQuota);

                } else if (!multiplePUs[anbimaDateQuota] && multiplePUsDate != null) {

                    multiplePUs[multiplePUsDate].days.push(anbimaDateQuota);

                } else if (multiplePUs[anbimaDateQuota]) {

                    if (!multiplePUsDate) {
                        multiplePUs['initPu'].days.push(anbimaDateQuota);
                    } else {
                        multiplePUs[multiplePUsDate].days.push(anbimaDateQuota);
                    }

                    multiplePUsDate = anbimaDateQuota;

                }
            }

            //Utilizado para fazer a rentabilidade do fundo na carteira
            let resultTransactionAmount = 0.0;
            let resultQtdQuotas = 0.0;
            let applicationsAmount = 0.0;
            let rescuesAmount = 0.0;


            if (addBusinessDays) {
                businessDaysAfterApplication++;
            }

            title.diaryPortfolioPl[anbimaDateQuota] = {

                pl: 0, //PL do dia após os rendimentos a partir das atualizações de quotas do fundo
                newPl: 0,
                result_app_rescues: 0,
                isBusiness: addBusinessDays

            };

            if (title.transactions) {
                //let listTransactionsInDay = assetLoop.transactions.filter(el => moment.utc(el.transaction_date).format('YYYY-MM-DD') == dateQuota);
                const listTransactionsInDay = title.transactions.filter(el => moment.utc(el.transaction_date).format('DD/MM/YYYY') == dateQuota); //Amortização não é considerada para o saldo e nem para a rentabilidade 

                if (listTransactionsInDay) {

                    listTransactionsInDay.forEach(transactionLoop => {

                        if (transactionLoop.operation_id == 1) { //Aplicação

                            resultTransactionAmount += parseFloat(transactionLoop.amount);
                            resultQtdQuotas += parseFloat(transactionLoop.quota_value_transaction)

                            //Obtendo quantidade de quotas da movimentação
                            transactionLoop.result_qtd_quotas = transactionLoop.amount / valueQuota;
                            title.sumQuotasApplication += transactionLoop.result_qtd_quotas;

                            applicationsAmount += parseFloat(transactionLoop.amount);
                            title.totalApplicationsAmount = applicationsAmount;

                        } else if (transactionLoop.operation_id == 2) {//Resgate

                            console.log('valueQuota Expired: ', valueQuota);
                            resultTransactionAmount -= parseFloat(transactionLoop.amount);
                            resultQtdQuotas -= parseFloat(transactionLoop.quota_value_transaction)

                            //Obtendo quantidade de quotas da movimentação

                            //Caso em que há resgate no dia do vencimento do título e nesse dia não
                            //há pu_anb, repete-se o pu do dia anterior
                            //let expiredValueQuota = null;
                            // if (!valueQuota && title.title_local_quotas[index - 1]) {
                            //     expiredValueQuota = title.title_local_quotas[index - 1].pu_anb;
                            // }
                            transactionLoop.result_qtd_quotas = transactionLoop.amount / valueQuota;
                            title.sumQuotasRescues += transactionLoop.result_qtd_quotas;

                            rescuesAmount += parseFloat(transactionLoop.amount);
                            title.totalRescuesAmount = rescuesAmount;

                        } else if (transactionLoop.operation_id == 3) {//Amortização

                            amortizationsByDate[anbimaDateQuota] = parseFloat(transactionLoop.amount);

                            //Amortização não é considerada para o saldo e nem para a rentabilidade
                            amortizationAmount += parseFloat(transactionLoop.amount);
                            allAmortizationAmount += parseFloat(transactionLoop.amount);
                        }
                    });

                    if (resultTransactionAmount && resultTransactionAmount != 0) {
                        resultAppRescuesByDate[anbimaDateQuota] = {}
                        resultAppRescuesByDate[anbimaDateQuota].amount = resultTransactionAmount;
                        resultAppRescuesByDate[anbimaDateQuota].qtdQuotas = resultQtdQuotas;
                    }


                }
            }

            if (title.balance_before == 0
                && tlq.date_quota == moment.utc(title.purchase_date).format('DD/MM/YYYY')) {

                //businessDaysAfterApplication++;
                addBusinessDays = true;

                if (title.transactions
                    && title.transactions[0]
                    && title.transactions.length == 1) {
                    title.transactions[0].result_qtd_quotas = parseFloat(title.quantity_purchased);
                }

                title.pl = resultTransactionAmount;
                title.qtd_quota = title.quantity_purchased;

                if (!newPlAdded) {

                    title.diaryPortfolioPl[formatPtStringDateToAnbimaDate(dateQuota)].newPl = title.pl;
                    title.diaryPortfolioPl[formatPtStringDateToAnbimaDate(dateQuota)].result_app_rescues = resultTransactionAmount;
                }

            }

            amortizationMap[formatPtStringDateToAnbimaDate(dateQuota)] = amortizationAmount;


        });

        if (isAllBusinessDays) {

            businessDaysAfterApplication = title.title_local_quotas.length;

            Object.entries(title.diaryPortfolioPl).map(async ([key, row]) => {
                row.isBusiness = true
            })

        }

        let businessDaysExpiredMonth = 0;
        if (isExpiredMonth && title.transactions && title.transactions.length) {

            const datesToBusiness = [];
            //Calcular os dias úteis
            title.title_local_quotas.forEach((tlq, index) => {
                if (stringPtDateToMoment(tlq.date_quota, true).isSameOrBefore(moment.utc(title.due_date))) {
                    businessDaysExpiredMonth++;
                    datesToBusiness.push(formatPtStringDateToAnbimaDate(tlq.date_quota));
                }
            })

            datesToBusiness.forEach(element => {
                title.diaryPortfolioPl[element].isBusiness = true
            });

            //Adicionando resgate ao result_app_rescues do dia do resgate

            const rescueAmount = title.transactions[0].amount * (-1);
            const dateRescueTransaction = formatPtStringDateToAnbimaDate(moment.utc(title.transactions[0].transaction_date).format("DD/MM/YYYY"));
            if (!isStrangeExpiredMonth) {
                title.diaryPortfolioPl[datesToBusiness[datesToBusiness.length - 1]].result_app_rescues = rescueAmount;
            } else {
                title.diaryPortfolioPl[dateRescueTransaction].isBusiness = true;
                title.transactions[0].result_qtd_quotas = parseFloat(title.transactions[0].quota_value_transaction);
                title.diaryPortfolioPl[dateRescueTransaction].result_app_rescues = rescueAmount;
            }
        }

        let dailyRent = null;
        let multipleDailyRents = {};
        //console.log('multiplePUs: ', multiplePUs);
        let currentDailyRent = null;
        if (Object.keys(multiplePUs).length) {

            //Múltiplos PUs
            //Calculando initDailyRent

            if (multiplePUs['initPu'].puValue != null) {

                if (multiplePUs.datesMultiplePUs && multiplePUs.datesMultiplePUs.length) {
                    const initMonthRent = (multiplePUs[multiplePUs.datesMultiplePUs[0]].puValue / multiplePUs['initPu'].puValue) - 1;
                    const initDailyRent = Math.pow((initMonthRent + 1), 1 / multiplePUs['initPu'].days.length);
                    currentDailyRent = initDailyRent;
                }

            } else {
                const initMonthRent = (multiplePUs['finalPu'].puValue / multiplePUs[multiplePUs.datesMultiplePUs[0]].puValue) - 1;
                const initDailyRent = Math.pow((initMonthRent + 1), 1 / multiplePUs[multiplePUs.datesMultiplePUs[0]].days.length);
                currentDailyRent = initDailyRent;
            }

            if (multiplePUs.datesMultiplePUs) {
                multiplePUs.datesMultiplePUs.forEach((datePU, index) => {

                    if (index != 0) {//removendo a operaão do primeiro dia pois já foi utilizado
                        //calcula outros dailyRents

                        const beforePU = multiplePUs[multiplePUs.datesMultiplePUs[index - 1]];
                        console.log("beforePU: ", beforePU);
                        console.log("index: ", index);
                        const middleMonthRent = (multiplePUs[datePU].puValue / beforePU.puValue) - 1;
                        const middleDailyRent = Math.pow((middleMonthRent + 1), 1 / beforePU.days.length);
                        multipleDailyRents[multiplePUs.datesMultiplePUs[index - 1]] = middleDailyRent;
                    }

                });

                //Calculando finalDailyRent
                const finalMonthRent = (multiplePUs['finalPu'].puValue / multiplePUs[multiplePUs.datesMultiplePUs[multiplePUs.datesMultiplePUs.length - 1]].puValue) - 1
                const finalDailyRent = Math.pow((finalMonthRent + 1), 1 / multiplePUs[multiplePUs.datesMultiplePUs[multiplePUs.datesMultiplePUs.length - 1]].days.length);
                multipleDailyRents[multiplePUs.datesMultiplePUs[multiplePUs.datesMultiplePUs.length - 1]] = finalDailyRent;
            }

            //Ainda não houve inicialização de dailyRent, caso em que não existem aplicações ou resgates
            if (!currentDailyRent) {
                const finalMonthRent = (multiplePUs['finalPu'].puValue / multiplePUs['initPu'].puValue) - 1;
                const finalDailyRent = Math.pow((finalMonthRent + 1), 1 / multiplePUs['initPu'].days.length);
                currentDailyRent = finalDailyRent;
            }

        } else {
            if (isStrangeExpiredMonth) {
                dailyRent = Math.pow((monthRent + 1), 1);
            } else {
                //Apenas um PU funcionamento convencional
                dailyRent = Math.pow((monthRent + 1), 1 / (isExpiredMonth ? businessDaysExpiredMonth : businessDaysAfterApplication));
            }
        }

        title.multipleDailyRents = multipleDailyRents;
        title.dailyRent = applyMaskAmount((dailyRent - 1) * 100);


        //console.log('resultAppRescuesByDate: ', resultAppRescuesByDate);
        title.resultAppRescuesByDate = resultAppRescuesByDate;

        const allKeys = Object.keys(title.diaryPortfolioPl);
        const firstKey = allKeys[0];
        const lastKey = allKeys[allKeys.length - 1];

        Object.entries(title.diaryPortfolioPl).map(async ([key, row]) => {

            if (row.isBusiness) {

                const transactionInDay = resultAppRescuesByDate[key];

                const dailyRentLoop = (isMarketMultiplePuTitle ? currentDailyRent : dailyRent);

                if (amortizationsByDate[key]) {
                    row.result_app_rescues = amortizationsByDate[key] * (-1);
                }

                row.pl = title.pl;

                title.pl *= dailyRentLoop;

                if (transactionInDay) {
                    row.result_app_rescues = transactionInDay.amount;
                    title.pl += transactionInDay.amount
                    title.qtd_quota += transactionInDay.qtdQuotas;
                }

                row.dailyRent = dailyRentLoop;
                row.newPl = title.pl;

                if (isMarketMultiplePuTitle && multiplePUs[key]) {
                    // console.log("TROCANDO DAILY RENT currentDailyRent: ", currentDailyRent);
                    // console.log("TROCANDO DAILY RENT key: ", key);
                    // console.log("TROCANDO DAILY RENT multipleDailyRents[key]: ", multipleDailyRents[key]);
                    currentDailyRent = multipleDailyRents[key];
                }


                if (isExpiredMonth && row.result_app_rescues != 0) {

                    row.newPl = row.newPl + row.result_app_rescues;
                }

            }

        })



        if (isExpiredMonth && title.transactions && title.transactions.length) {

            //Comentando em 16/06/2023 para contemplar o caso de caçador

            // title.pl -= title.transactions[0].amount;
            // title.qtd_quota -= title.transactions[0].quota_value_transaction
        } else {
            //title.pl -= cuponsMonthAmounts;
        }

        //normalizando diaryPortfolioPl com possíveis amortizações


    }
}

function operationLFTitle(title) {

    title.aOperationMethod = TITLES_OPERATIONS_METHODS.PRIVATE_IPCA;

    let businessDaysAfterApplication = 0;
    let addBusinessDays = false;
    let newPlAdded = false;

    let countIpcaDpLength = 0;

    if (title.cdi_month) {

        const startBussinessDay = title.cdi_month[0].date
        const endBussinessDay = title.cdi_month[title.cdi_month.length - 1].date

        const isAllBusinessDays = !title.transactions || (title.transactions && title.transactions.length > 0
            && (title.transactions.filter(el => el.operation_id == 3).length == title.transactions.length));

        //Mês de vencimento do título
        const isExpiredMonth = (moment.utc(title.asset_title_due_date).isSameOrAfter(stringPtDateToMoment(startBussinessDay, true))
            && moment.utc(title.asset_title_due_date).isSameOrBefore(stringPtDateToMoment(endBussinessDay, true)))

        //Resgate antecipado
        const isAnticipatedRescueMonth = title.quota_amount_now <= 0
            && title.transactions
            && title.transactions.length == 1
            && title.transactions[0].operation_id == 2;

        const isParcialRescue = title.quota_amount_now > 0 && title.transactions?.length == 1 && title.transactions[0].operation_id == 2;

        //Em caso de vencimento do título, ocorre o lançamento e o PU é calculado de forma diferente
        const pu = isExpiredMonth
            && title.transactions
            && title.transactions.length ?
            parseFloat(title.transactions[0].amount) / parseFloat(title.transactions[0].quota_value_transaction)
            :
            (
                (isAnticipatedRescueMonth) ?//Possibilidade de resgate total antes do vencimento
                    parseFloat(title.transactions[0].amount) / parseFloat(title.transactions[0].quota_value_transaction)
                    :
                    title.balance_now / title.quota_amount_now
            )


        const multiplePUs = {};
        if (isParcialRescue) {

            multiplePUs['initPu'] = {
                puValue: title.quota_amount_before ? title.balance_before / title.quota_amount_before : null,
                days: [],
            }

            title.transactions.filter(el => el.operation_id != 3).forEach(transactionLoop => {

                if (!multiplePUs['datesMultiplePUs']) {
                    multiplePUs['datesMultiplePUs'] = [];
                }
                const date = moment.utc(transactionLoop.transaction_date).format('YYYY-MM-DD');
                multiplePUs.datesMultiplePUs.push(date);
                multiplePUs[date] = {
                    puValue: parseFloat(transactionLoop.amount) / parseFloat(transactionLoop.quota_value_transaction),
                    days: [],
                }

            });

            let finalPu = null;
            if (title.quota_amount_now) { //existe rentabilização até o fim do mês
                finalPu = pu;
            } else {
                finalPu = multiplePUs.datesMultiplePUs
                    && multiplePUs.datesMultiplePUs.length
                    && multiplePUs[multiplePUs.datesMultiplePUs.length - 1] ? multiplePUs[multiplePUs.datesMultiplePUs.length - 1].puValue : null;

            }
            multiplePUs['finalPu'] = {
                puValue: finalPu,
                days: [],
            }

        }

        title.multiplePUs = multiplePUs;
        title.puInit = pu;

        console.log("multiplePUs: ", multiplePUs);

        if (isExpiredMonth
            && title.transactions
            && title.transactions.length) {
            console.log('parseFloat(title.transactions[0].amount): ', parseFloat(title.transactions[0].amount));
            console.log('parseFloat(title.transactions[0].quota_value_transaction): ', parseFloat(title.transactions[0].quota_value_transaction));

            title.isExpiredMonth = true;
        }

        //valorDoResgate / qtdCotasResgatada : title.balance_now / title.quota_amount_now;

        let monthRent = 0;
        //Indica que é o mês da primeira aplicação
        if (title.transactions
            && title.transactions.length == 1
            && title.transactions[0].operation_id != 3 && !isExpiredMonth && !isAnticipatedRescueMonth) {

            title.monthRentMode = 1;
            monthRent = (pu / title.price_title) - 1;

        } else {

            title.monthRentMode = 2;
            const puBefore = title.balance_before / title.quota_amount_before;
            monthRent = (pu / puBefore) - 1;

        }

        console.log("MONTH RENT CALCULADO: ", monthRent);

        title.monthRent = monthRent;

        // console.log('isExpiredMonth: ', isExpiredMonth);
        // console.log('moment.utc(title.asset_title_due_date): ', moment.utc(title.asset_title_due_date).format('DD/MM/YYYY'));
        // console.log('startBussinessDay: ', startBussinessDay);
        // console.log('comparando [' + moment.utc(title.asset_title_due_date).format('DD/MM/YYYY') + '] isSameOrAfter [' + stringPtDateToMoment(startBussinessDay, true).format('DD/MM/YYYY') + ']:', moment.utc(title.asset_title_due_date).isSameOrAfter(stringPtDateToMoment(startBussinessDay, true)));
        // console.log('endBussinessDay: ', endBussinessDay);

        const resultAppRescuesByDate = {}
        const amortizationsByDate = {}
        let multiplePUsDate = null

        const amortizationMap = {};
        let allAmortizationAmount = 0;

        title.cdi_month.forEach((tlq, index) => {

            const dateQuota = tlq.date;

            //Utilizado para fazer a rentabilidade do fundo na carteira
            let resultTransactionAmount = 0.0;
            let amortizationAmount = 0.0;
            let applicationsAmount = 0.0;
            let rescuesAmount = 0.0;
            let resultQtdQuotas = 0.0;

            const valueQuota = tlq.value;

            const anbimaDateQuota = formatPtStringDateToAnbimaDate(dateQuota);

            if (isParcialRescue) {

                if (!multiplePUs[anbimaDateQuota] && multiplePUsDate == null) {

                    multiplePUs['initPu'].days.push(anbimaDateQuota);

                } else if (!multiplePUs[anbimaDateQuota] && multiplePUsDate != null) {

                    multiplePUs[multiplePUsDate].days.push(anbimaDateQuota);

                } else if (multiplePUs[anbimaDateQuota]) {

                    if (!multiplePUsDate) {
                        multiplePUs['initPu'].days.push(anbimaDateQuota);
                    } else {
                        multiplePUs[multiplePUsDate].days.push(anbimaDateQuota);
                    }

                    multiplePUsDate = anbimaDateQuota;

                }
            }


            if (addBusinessDays) {
                businessDaysAfterApplication++;
            }

            title.diaryPortfolioPl[formatPtStringDateToAnbimaDate(dateQuota)] = {

                pl: 0, //PL do dia após os rendimentos a partir das atualizações de quotas do fundo
                newPl: 0,
                result_app_rescues: 0,
                isBusiness: addBusinessDays

            };

            //console.log("DATE QUOTA: ", dateQuota);
            //console.log("purchase_date: ", moment.utc(title.purchase_date).format('DD/MM/YYYY'));
            if (title.balance_before == 0
                && dateQuota == moment.utc(title.purchase_date).format('DD/MM/YYYY')) {

                //businessDaysAfterApplication++;
                addBusinessDays = true;

                if (title.transactions && title.transactions[0] && title.transactions.length == 1) {
                    title.transactions[0].result_qtd_quotas = parseFloat(title.quantity_purchased);
                    title.pl = parseFloat(title.transactions[0].amount);
                    title.qtd_quota = parseFloat(title.quantity_purchased);
                }

                //console.log("ENTROU DIA DE APLICAÇÂO: ", formatPtStringDateToAnbimaDate(dateQuota));


                if (!newPlAdded && title.transactions && title.transactions.length) {

                    title.diaryPortfolioPl[formatPtStringDateToAnbimaDate(dateQuota)].newPl = title.pl;
                    title.diaryPortfolioPl[formatPtStringDateToAnbimaDate(dateQuota)].result_app_rescues = parseFloat(title.transactions[0].amount);
                }

            }

            //Caso tenha amortização
            title.pl -= amortizationAmount;

            if (title.transactions && isParcialRescue) {
                //let listTransactionsInDay = assetLoop.transactions.filter(el => moment.utc(el.transaction_date).format('YYYY-MM-DD') == dateQuota);
                const listTransactionsInDay = title.transactions.filter(el => moment.utc(el.transaction_date).format('DD/MM/YYYY') == dateQuota); //Amortização não é considerada para o saldo e nem para a rentabilidade 

                if (listTransactionsInDay) {

                    listTransactionsInDay.forEach(transactionLoop => {

                        if (transactionLoop.operation_id == 1) { //Aplicação

                            resultTransactionAmount += parseFloat(transactionLoop.amount);
                            resultQtdQuotas += parseFloat(transactionLoop.quota_value_transaction)

                            //Obtendo quantidade de quotas da movimentação
                            transactionLoop.result_qtd_quotas = transactionLoop.amount / valueQuota;
                            title.sumQuotasApplication += transactionLoop.result_qtd_quotas;

                            applicationsAmount += parseFloat(transactionLoop.amount);
                            title.totalApplicationsAmount = applicationsAmount;

                        } else if (transactionLoop.operation_id == 2) {//Resgate

                            resultTransactionAmount -= parseFloat(transactionLoop.amount);
                            resultQtdQuotas -= parseFloat(transactionLoop.quota_value_transaction)

                            transactionLoop.result_qtd_quotas = transactionLoop.amount / valueQuota;
                            title.sumQuotasRescues += transactionLoop.result_qtd_quotas;

                            rescuesAmount += parseFloat(transactionLoop.amount);
                            title.totalRescuesAmount = rescuesAmount;

                        } else if (transactionLoop.operation_id == 3) {//Amortização

                            amortizationsByDate[anbimaDateQuota] = parseFloat(transactionLoop.amount);

                            //Amortização não é considerada para o saldo e nem para a rentabilidade
                            amortizationAmount += parseFloat(transactionLoop.amount);
                            allAmortizationAmount += parseFloat(transactionLoop.amount);
                        }
                    });

                    if (resultTransactionAmount && resultTransactionAmount != 0) {
                        resultAppRescuesByDate[anbimaDateQuota] = {}
                        resultAppRescuesByDate[anbimaDateQuota].amount = resultTransactionAmount;
                        resultAppRescuesByDate[anbimaDateQuota].qtdQuotas = resultQtdQuotas;
                    }


                }
            }

        });

        //businessDaysAfterApplication APENAS ATÉ O DIA DO RESGATE
        if ((isAllBusinessDays || isParcialRescue) && !isExpiredMonth) {

            businessDaysAfterApplication = title.cdi_month.length;

            Object.entries(title.diaryPortfolioPl).map(async ([key, row]) => {
                row.isBusiness = true
            })

        }


        let businessDaysExpiredMonth = 0;

        title.isAnticipatedRescueMonth = isAnticipatedRescueMonth;
        title.isExpiredMonth = isExpiredMonth;
        title.isParcialRescue = isParcialRescue;
        if (isExpiredMonth || isAnticipatedRescueMonth) {

            const datesToBusiness = [];
            //Calcular os dias úteis
            title.cdi_month.forEach((tlq, index) => {
                if (isExpiredMonth) {
                    if (stringPtDateToMoment(tlq.date, true).isSameOrBefore(moment.utc(title.asset_title_due_date))) {
                        businessDaysExpiredMonth++;
                        datesToBusiness.push(formatPtStringDateToAnbimaDate(tlq.date));
                    }
                } else if (isAnticipatedRescueMonth) {
                    if (stringPtDateToMoment(tlq.date, true).isSameOrBefore(moment.utc(title.transactions[0].transaction_date))) {
                        businessDaysExpiredMonth++;
                        datesToBusiness.push(formatPtStringDateToAnbimaDate(tlq.date));
                    }
                }

            })

            //title.datesToBusiness = datesToBusiness;

            datesToBusiness.forEach(element => {
                title.diaryPortfolioPl[element].isBusiness = true
            });

            //Adicionando resgate ao result_app_rescues do dia do resgate
            if (title.transactions && title.transactions.length) {

                title.diaryPortfolioPl[datesToBusiness[datesToBusiness.length - 1]].result_app_rescues = title.transactions[0].amount * (-1);
            }
        }

        title.businessDays = (isExpiredMonth || isAnticipatedRescueMonth ? businessDaysExpiredMonth : businessDaysAfterApplication);

        let dailyRent = null;

        if (isParcialRescue) {
            let multipleDailyRents = {};
            //console.log('multiplePUs: ', multiplePUs);
            let currentDailyRent = null;
            if (Object.keys(multiplePUs).length) {

                //Múltiplos PUs
                //Calculando initDailyRent

                if (multiplePUs['initPu'].puValue != null) {

                    if (multiplePUs.datesMultiplePUs && multiplePUs.datesMultiplePUs.length) {
                        const initMonthRent = (multiplePUs[multiplePUs.datesMultiplePUs[0]].puValue / multiplePUs['initPu'].puValue) - 1;
                        const initDailyRent = Math.pow((initMonthRent + 1), 1 / multiplePUs['initPu'].days.length);
                        currentDailyRent = initDailyRent;
                    }

                } else {
                    const initMonthRent = (multiplePUs['finalPu'].puValue / multiplePUs[multiplePUs.datesMultiplePUs[0]].puValue) - 1;
                    const initDailyRent = Math.pow((initMonthRent + 1), 1 / multiplePUs[multiplePUs.datesMultiplePUs[0]].days.length);
                    currentDailyRent = initDailyRent;
                }

                if (multiplePUs.datesMultiplePUs) {
                    multiplePUs.datesMultiplePUs.forEach((datePU, index) => {

                        if (index != 0) {//removendo a operaão do primeiro dia pois já foi utilizado
                            //calcula outros dailyRents

                            const beforePU = multiplePUs[multiplePUs.datesMultiplePUs[index - 1]];
                            console.log("beforePU: ", beforePU);
                            console.log("index: ", index);
                            const middleMonthRent = (multiplePUs[datePU].puValue / beforePU.puValue) - 1;
                            const middleDailyRent = Math.pow((middleMonthRent + 1), 1 / beforePU.days.length);
                            multipleDailyRents[multiplePUs.datesMultiplePUs[index - 1]] = middleDailyRent;
                        }

                    });

                    //Calculando finalDailyRent
                    const finalMonthRent = (multiplePUs['finalPu'].puValue / multiplePUs[multiplePUs.datesMultiplePUs[multiplePUs.datesMultiplePUs.length - 1]].puValue) - 1
                    const finalDailyRent = Math.pow((finalMonthRent + 1), 1 / multiplePUs[multiplePUs.datesMultiplePUs[multiplePUs.datesMultiplePUs.length - 1]].days.length);
                    multipleDailyRents[multiplePUs.datesMultiplePUs[multiplePUs.datesMultiplePUs.length - 1]] = finalDailyRent;
                }

                //Ainda não houve inicialização de dailyRent, caso em que não existem aplicações ou resgates
                if (!currentDailyRent) {
                    const finalMonthRent = (multiplePUs['finalPu'].puValue / multiplePUs['initPu'].puValue) - 1;
                    const finalDailyRent = Math.pow((finalMonthRent + 1), 1 / multiplePUs['initPu'].days.length);
                    currentDailyRent = finalDailyRent;
                }

            } else {

                //Apenas um PU funcionamento convencional
                dailyRent = Math.pow((monthRent + 1), 1 / (isExpiredMonth ? businessDaysExpiredMonth : businessDaysAfterApplication));

            }

            title.multipleDailyRents = multipleDailyRents;
            title.dailyRent = applyMaskAmount((dailyRent - 1) * 100);

            Object.entries(title.diaryPortfolioPl).map(async ([key, row]) => {

                if (row.isBusiness) {

                    const transactionInDay = resultAppRescuesByDate[key];

                    const dailyRentLoop = (isParcialRescue ? currentDailyRent : dailyRent);

                    if (amortizationsByDate[key]) {
                        row.result_app_rescues = amortizationsByDate[key] * (-1);
                    }

                    row.pl = title.pl;

                    title.pl *= dailyRentLoop;

                    if (transactionInDay) {
                        row.result_app_rescues = transactionInDay.amount;
                        title.pl += transactionInDay.amount
                        title.qtd_quota += transactionInDay.qtdQuotas;
                    }

                    row.dailyRent = dailyRentLoop;
                    row.newPl = title.pl;

                    if (isParcialRescue && multiplePUs[key]) {
                        currentDailyRent = multipleDailyRents[key];
                    }


                    if (isExpiredMonth && row.result_app_rescues != 0) {

                        row.newPl = row.newPl + row.result_app_rescues;
                    }

                }

            })
        } else {
            dailyRent = Math.pow((monthRent + 1), 1 / title.businessDays);

            Object.entries(title.diaryPortfolioPl).map(async ([key, row]) => {

                if (row.isBusiness) {

                    row.pl = title.pl;
                    title.pl *= dailyRent;
                    row.newPl = title.pl;

                    if (
                        (isExpiredMonth && row.result_app_rescues != 0) // caso de resgate no mês de vencimento
                        ||
                        (isAnticipatedRescueMonth && row.result_app_rescues != 0) //caso de resgate antecipado
                        //||(isParcialRescue && row.result_app_rescues != 0) //caso de resgate antecipado
                    ) {
                        console.log('DATA: ', key);
                        console.log('title.pl: ', title.pl);
                        console.log('dailyRent: ', dailyRent);

                        row.newPl = row.newPl + row.result_app_rescues;
                    }

                    //console.log('PL NEW TITLE: ', row.newPl);
                }

            })
        }

        if (((isExpiredMonth || isAnticipatedRescueMonth) && title.transactions && title.transactions.length)) {
            title.pl -= title.transactions[0].amount;
            title.qtd_quota -= title.transactions[0].quota_value_transaction
        }
        //console.log('title.diaryPortfolioPl AFTER: ', title.diaryPortfolioPl);
    }


}

function isGroupedLiquidityTitle(title) {

    return title.subAssetsTitles && title.subAssetsTitles.length > 0 && title.with_liquidity;
}

function totalsGroupedTitle(title) {

    //console.log('totalsGroupedTitle: ', _.cloneDeep(title));
    let totalBalanceNow = 0;
    let totalBalanceBefore = 0;
    let totalApplications = 0;
    let totalRescues = 0;
    let totalAmortizations = 0;
    let totalParticipation = 0;
    let deviceAbbreviation = "";

    if (title.subAssetsTitles && title.subAssetsTitles.length > 0) {
        deviceAbbreviation = title.subAssetsTitles[0].device_abbreviation;
    }

    title.subAssetsTitles.forEach(subAssetTitle => {

        totalBalanceNow += parseFloat(subAssetTitle.balance_now);
        totalBalanceBefore += parseFloat(subAssetTitle.balance_before);
        totalParticipation += parseFloat(subAssetTitle.participation);


        //Somando aplicações
        if (subAssetTitle.transactions) {
            subAssetTitle.transactions.filter(el => el.operation_id == 1).forEach(transaction => {

                totalApplications += parseFloat(transaction.amount);
            });

            //Somando resgates
            subAssetTitle.transactions.filter(el => el.operation_id == 2).forEach(transaction => {

                totalRescues += parseFloat(transaction.amount);
            });

            //Somando amortizações
            subAssetTitle.transactions.filter(el => el.operation_id == 3).forEach(transaction => {

                totalAmortizations += parseFloat(transaction.amount);
            });
        }


    });

    totalParticipation = applyMaskAmount(totalParticipation);

    return {
        totalBalanceNow,
        totalBalanceBefore,
        totalApplications,
        totalRescues,
        totalAmortizations,
        totalParticipation,
        deviceAbbreviation
    }
}



export function getRentMonthTitle(title) {

    //console.log("Entrando rent percent month: ", title);
    //Título liquidez agrupado
    if (title.subAssetsTitles && title.subAssetsTitles.length) {

        //console.log("Entrando GROUP TITLE: ", title);
        const { totalBalanceNow, totalBalanceBefore, totalApplications, totalRescues, totalAmortizations } = totalsGroupedTitle(title);

        //MODE 1
        title.modeRent = '1'
        return (((totalBalanceNow + (totalRescues + totalAmortizations)) / (totalBalanceBefore + totalApplications)) - 1) * 100;

    } else {

        //console.log('PASSANDO CALCULO RENT TITLE: ');

        let balanceNow = null;
        const coupons = title.transactions ? title.transactions.filter(el => el.operation_id == 3) : null;

        if (coupons && coupons.length == 1) {
            const coupon = coupons[0];
            console.log("SOMANDO CUPOM: ", parseFloat(coupon.amount))
            balanceNow = title.pl + parseFloat(coupon.amount);
        } else {
            balanceNow = title.pl;
        }

        if (isPrivateCdiTitle(title)) {

            if (title.transactions && title.transactions.length) {
                const { totalApplications, totalRescues } = getTotalApplicationsAndRescuesByTransactions(title.transactions);
                //MODE 3
                title.modeRent = '3'
                return (((balanceNow + totalRescues) / (title.balance_before + totalApplications)) - 1) * 100
            }

        }

        const balanceBefore = title.balance_before == 0 ?
            (parseFloat(title.price_title) * parseFloat(title.quantity_purchased))
            :
            title.balance_before


        if (title.transactions
            && title.transactions.length > 1
            && isPrivatePreFixedTitle(title)) {

            const { totalApplications, totalRescues } = getTotalApplicationsAndRescuesByTransactions(title.transactions);
            //MODE 2
            title.modeRent = '2'
            return (((balanceNow + totalRescues) / (balanceBefore + totalApplications)) - 1) * 100
        }

        if (isPrivateIpcaTitle(title) && ((title.isExpiredMonth || title.isAnticipatedRescueMonth) && title.transactions?.length)) {

            //MODE 4
            title.modeRent = '4'
            return ((parseFloat(title.transactions[0].amount) / parseFloat(balanceBefore)) - 1) * 100

        }

        if (title.isMarketMultiplePuTitle) {

            const { totalApplications, totalRescues } = getTotalApplicationsAndRescuesByTransactions(title.transactions);
            //MODE 5
            title.modeRent = '5'
            return (((balanceNow + totalRescues) / (title.balance_before + totalApplications)) - 1) * 100

        } else {

            //MODE 6

            if ((title.isExpiredMonth || title.isAnticipatedRescueMonth) && title.transactions?.length) {

                title.modeRent = '61'
                return ((parseFloat(title.transactions[0].amount) / parseFloat(balanceBefore)) - 1) * 100

            } else if (isGenericTitle(title)) {

                title.modeRent = '62'
                return 0;

            } else {

                title.modeRent = '63'
                const { totalApplications, totalRescues } = getTotalApplicationsAndRescuesByTransactions(title.transactions);
                return (((balanceNow + totalRescues) / (title.balance_before + totalApplications)) - 1) * 100
                //return ((balanceNow / balanceBefore) - 1) * 100
            }
        }



    }


}

export function getRentInMoneyMonthTitle(title) {

    if (title.subAssetsTitles && title.subAssetsTitles.length > 0) {

        const { totalBalanceNow, totalBalanceBefore, totalApplications, totalRescues, totalAmortizations } = totalsGroupedTitle(title);

        //MODE 1
        title.modeRentMoney = '1';
        return totalBalanceNow - totalApplications + (totalRescues + totalAmortizations) - totalBalanceBefore;

    } else {
        let balanceNow = null;
        const coupons = title.transactions ? title.transactions.filter(el => el.operation_id == 3) : null;

        if (coupons && coupons.length == 1) {
            const coupon = coupons[0];
            balanceNow = title.pl + parseFloat(coupon.amount);
        } else {
            balanceNow = title.pl;
        }

        const balanceBefore = title.balance_before == 0 ?
            (parseFloat(title.price_title) * parseFloat(title.quantity_purchased))
            :
            title.balance_before

        if ((title.isExpiredMonth || title.isAnticipatedRescueMonth) && title.transactions?.length) {
            //MODE 2
            title.modeRentMoney = '2';
            return (title.transactions[0].amount - balanceBefore)
        } else {
            //MODE 3
            title.modeRentMoney = '3';
            if (title.isMarketMultiplePuTitle) { //LF marcada a mercado
                const { totalApplications, totalRescues } = getTotalApplicationsAndRescuesByTransactions(title.transactions);

                return balanceNow - totalApplications + totalRescues - parseFloat(title.balance_before);
            } else if (isGenericTitle(title)) {
                return 0;
            } else {

                const { totalApplications, totalRescues } = getTotalApplicationsAndRescuesByTransactions(title.transactions);

                return balanceNow - totalApplications + totalRescues - parseFloat(title.balance_before);
                //return (balanceNow - balanceBefore)
            }
        }
    }


}

export function calculateRentTitles(titles) {
    console.log('CALCULAR RENTABILDIADE DOS TITULOS: ', titles);

    titles.forEach(titleLoop => {

        titleLoop.balance_before = titleLoop.balance_before ? parseFloat(titleLoop.balance_before) : null;
        titleLoop.balance_now = titleLoop.balance_now ? parseFloat(titleLoop.balance_now) : null;

        titleLoop.pl = titleLoop.balance_before;
        titleLoop.quota_amount_now = parseFloat(titleLoop.quota_amount_now);
        titleLoop.quota_amount_before = parseFloat(titleLoop.quota_amount_before);
        titleLoop.qtd_quota = titleLoop.quota_amount_before;

        const monthTax = calcMonthTax(titleLoop);
        const monthIpcaDp = calcMonthIpcaDp(titleLoop);
        const rentTitle = calcRentTitle(monthTax, monthIpcaDp, titleLoop);

        titleLoop.rent = {};
        titleLoop.diaryPortfolioPl = {};

        //Trazendo price_title para float
        if (titleLoop.price_title) {
            titleLoop.price_title = parseFloat(titleLoop.price_title);
        }

        if (isGenericTitle(titleLoop)) {

            operationRentGenericTitle(titleLoop);

        } else if (isPrivateCdiTitle(titleLoop)) {

            operationRentCDITitle(titleLoop);

        } else if (isPrivatePreFixedTitle(titleLoop)) {

            //console.log("PRIVADO PRE")
            operationPreFixedTitle(titleLoop);

        } else if (isPrivateIpcaTitle(titleLoop)) {

            operationLFTitle(titleLoop);

        } else {

            //operationRentDayADay(titleLoop);
            operationRentDayADayNew(titleLoop);

        }



        titleLoop.rentMoneyInMonth = getRentInMoneyMonthTitle(titleLoop);
        //console.log('CLONE TITLE: ', _.cloneDeep(titleLoop));
        titleLoop.rentPercentInMonth = getRentMonthTitle(titleLoop);

        // console.log('monthTax: ', monthTax);
        // console.log('monthIpcaDp: ', monthIpcaDp);
        // console.log('rentTitle: ', rentTitle);
        // console.log('rentTitle: ', titleLoop.qtd_quotas_sensibilized_final);

    });

}

//Se o título é público = true
export function needQuotasTitle(titleLoop) {
    return !isPrivateCdiTitle(titleLoop)
        && !isPrivatePreFixedTitle(titleLoop)
        && !isPrivateIpcaTitle(titleLoop);
}

export function isPrivateCdiTitle(titleLoop) {
    return titleLoop.sector == 'PRIVADO'
        && titleLoop.indexer == 'CDI';
}

export function isPrivateIpcaTitle(titleLoop) {
    return titleLoop.sector == 'PRIVADO'
        && titleLoop.indexer == 'IPCA';
}

export function isPrivatePreFixedTitle(titleLoop) {
    return titleLoop.sector == 'PRIVADO'
        && !titleLoop.indexer;
}

//Imóveis
export function isGenericTitle(titleLoop) {
    if (titleLoop) {

        return titleLoop.label_title_type == GENERIC_ASSET_TITLES_TYPE;
    } else return false
}

export async function doSaveTitleClientRent(clientId, month, year, titleAsset) {

    console.log('title rent to save: ', titleAsset);
    const rentTitleToSave = {
        title_id: titleAsset.title_id,
        client_id: clientId,
        rent_month: getRentMonthTitle(titleAsset),
        date_rent: getFirstDayMonth(month, year),
        month: month,
        year: year
    }
    const responseCreate = await saveTitleClientRent(rentTitleToSave);

    if (responseCreate.success) {
        return {
            success: true,
        }
    } else {
        return {
            success: false,
        }
    }

}

export async function doSaveCoupons(coupons) {

    let allSuccess = true;
    for (let index = 0; index < coupons.length; index++) {
        const coupon = coupons[index];

        const response = await saveCoupon(coupon);
        if (!response.success) {
            allSuccess = false;
        }
    }

    return allSuccess;
}


//Verifica se existem amortizações a serem criadas para os títulos
export function verifyTitlesAmortizations(titles) {

    const amortizationsToCreate = [];
    titles.filter(el => el.date_coupon).forEach(title => {

        //console.log("TITLE TO AMORTIZATION: ", title);
        if (!title.transactions ||
            (title.transactions && !title.transactions.some(el =>
                moment.utc(el.transaction_date).format('DD/MM/YYYY') == moment.utc(title.date_coupon).format('DD/MM/YYYY')))) {

            //Verificar se o cupom é posterior a data de compra do título
            if (moment.utc(title.date_coupon).isAfter(moment.utc(title.purchase_date))) {
                amortizationsToCreate.push({
                    title: getTitleCod(title),
                    titleAssetId: title.id,
                    purchaseDate: title.purchase_date,
                    dateCoupon: title.date_coupon,
                    valueCoupon: title.value_coupon,
                    valueAmortization: parseFloat(title.qtd_quota) * parseFloat(title.value_coupon),
                })
            }


        }


    });

    return amortizationsToCreate;

}

//Verifica se o PU calculado do asset title é muito divergente dos PUs obtidos da anbima 
export function checkAssetTitleDivergentBalance(assetTitle) {
    console.log("Checando NTNB: ", assetTitle.titleCod);

    if (assetTitle.isStrangeExpiredMonth) return false;

    if (assetTitle.aOperationMethod === TITLES_OPERATIONS_METHODS.PUBLIC) {

        if (assetTitle.title_local_quotas?.length) {

            const puCalculated = assetTitle.puInit;
            const referencePu = assetTitle.title_local_quotas[0].pu_anb;

            const absoluteDiff = Math.abs(puCalculated - referencePu);
            if (absoluteDiff > (50 / 100 * referencePu)) {
                return true;
            }
        }
    }

    return false;
}

export function getDueDateTitle(title) {

    // if (isPrivatePreFixedTitle(title) || isPrivateIpcaTitle(title) && title.asset_title_due_date) {
    //     return title.asset_title_due_date;
    // }
    if (title.sector == 'PRIVADO') {
        return title.asset_title_due_date;
    }

    return title.due_date;

}

//Agrupar os ativos de título a fim de não criar uma lista enorme de ativos na tela de carteira e relatório
export function groupAssetTitles(allAssets) {

    if (allAssets.some(el => el.title_id)) {
        console.log("PASSOU AGRUPAMENTO DE TITULOS");

        const returnAssets = [];
        const publicTitles = { fund_name: PUBLIC_TITLES, subAssetsTitles: [] };
        const privateTitles = { fund_name: PRIVATE_TITLES, subAssetsTitles: [] };
        const genericAssets = { fund_name: GENERIC_ASSETS, subAssetsTitles: [] };

        allAssets.forEach(ass => {
            if (!ass.title_id) {
                returnAssets.push(ass);
            } else {

                if (ass.label_title_type == GENERIC_ASSET_TITLES_TYPE) {
                    genericAssets.subAssetsTitles.push(ass);
                } else if (ass.sector == 'PUBLICO') {
                    publicTitles.subAssetsTitles.push(ass);
                } else if (ass.sector == 'PRIVADO') {
                    privateTitles.subAssetsTitles.push(ass);
                }

            }

        });

        //Criando asset de PUBLIC_TITLES
        const responsePublicTotals = totalsGroupedTitle(publicTitles);
        publicTitles.balance_now = responsePublicTotals.totalBalanceNow;
        publicTitles.balance_before = responsePublicTotals.totalBalanceBefore;
        publicTitles.totalApplications = responsePublicTotals.totalApplications;
        publicTitles.totalRescues = responsePublicTotals.totalRescues;
        publicTitles.participation = responsePublicTotals.totalParticipation;
        publicTitles.device_abbreviation = responsePublicTotals.deviceAbbreviation;

        publicTitles.rentMoneyInMonth = getRentInMoneyMonthTitle(publicTitles);
        publicTitles.rentPercentInMonth = getRentMonthTitle(publicTitles);

        if (publicTitles.subAssetsTitles.length) {
            returnAssets.push(publicTitles);
        }

        //Criando asset de PRIVATE_TITLES
        const responsePrivateTotals = totalsGroupedTitle(privateTitles);
        privateTitles.balance_now = responsePrivateTotals.totalBalanceNow;
        privateTitles.balance_before = responsePrivateTotals.totalBalanceBefore;
        privateTitles.totalApplications = responsePrivateTotals.totalApplications;
        privateTitles.totalRescues = responsePrivateTotals.totalRescues;
        privateTitles.participation = responsePrivateTotals.totalParticipation;
        privateTitles.device_abbreviation = responsePrivateTotals.deviceAbbreviation;

        privateTitles.rentMoneyInMonth = getRentInMoneyMonthTitle(privateTitles);
        privateTitles.rentPercentInMonth = getRentMonthTitle(privateTitles);

        if (privateTitles.subAssetsTitles.length) {
            returnAssets.push(privateTitles);
        }

        //Criando asset de GENERIC_ASSETS
        const responseGenericTotals = totalsGroupedTitle(genericAssets);
        genericAssets.balance_now = responseGenericTotals.totalBalanceNow;
        genericAssets.balance_before = responseGenericTotals.totalBalanceBefore;
        genericAssets.totalApplications = responseGenericTotals.totalApplications;
        genericAssets.totalRescues = responseGenericTotals.totalRescues;
        genericAssets.participation = responseGenericTotals.totalParticipation;
        genericAssets.device_abbreviation = responseGenericTotals.deviceAbbreviation;

        genericAssets.rentMoneyInMonth = 0;
        genericAssets.rentPercentInMonth = 0;

        if (genericAssets.subAssetsTitles.length) {
            returnAssets.push(genericAssets);
        }

        return returnAssets;
    } else {
        return allAssets;
    }





}

export function organizeHistoricAmortizationsInAssetsTitles(assets, amortizations) {

    const mapAmortizations = {};

    amortizations.forEach(amortization => {
        if (!mapAmortizations[amortization.title_asset_id]) {
            mapAmortizations[amortization.title_asset_id] = [];
        }

        mapAmortizations[amortization.title_asset_id].push({
            transaction_date: amortization.transaction_date,
            amount: amortization.amount
        });
    });

    console.log('mapAmortizations: ', mapAmortizations);

    assets.forEach(ass => {

        if (mapAmortizations[ass.id]) {
            ass.historicAmortizations = mapAmortizations[ass.id];
        }

    });
}

export async function getAnaliticTitles(clientId, startDate, endDate, month, year) {

    //const response = await getClientAllAssetsTitleByDateAndRegime(client.id, startDate, endDate, null);
    const response = await getClientAllAssetsTitleByDateAndRegimeWithInits(clientId, startDate, endDate, null);
    const responseTitleTransactions = await sumTitlesTransactionsInMonthByPeriod(clientId, startDate, endDate);
    // console.log('responseTitleTransactions: ', responseTitleTransactions);

    const groupedTransactions = groupTitleSumTransactions(responseTitleTransactions.body.rows, year, 'title_asset_id')
    // console.log('groupedTransactions: ', groupedTransactions);

    if (response.success) {

        console.log('titles: ', response.body);
        const titlesRows = response.body.rows;

        const period = month + '/' + year;

        const finalTotals = {

            purchaseAmount: 0,
            currentAmount: 0,
            rent: 0,
            rentMoney: 0,

            balance_now: 0,
            balance_before: 0,
            totalRescuesAmount: 0,
            totalAmortizationAmount: 0,
            totalApplicationsAmount: 0
        };
        const finalTotalsPublic = {

            purchaseAmount: 0,
            currentAmount: 0,
            rent: 0,
            rentMoney: 0,

            balance_now: 0,
            balance_before: 0,
            totalRescuesAmount: 0,
            totalAmortizationAmount: 0,
            totalApplicationsAmount: 0
        };
        const finalTotalsPrivate = {

            purchaseAmount: 0,
            currentAmount: 0,
            rent: 0,
            rentMoney: 0,

            balance_now: 0,
            balance_before: 0,
            totalRescuesAmount: 0,
            totalAmortizationAmount: 0,
            totalApplicationsAmount: 0
        };

        const activesTitles = [];

        const transactionsInTitles = responseTitleTransactions?.body?.rows;

        titlesRows.forEach(title => {

            if (title.balance_now > 0 || (transactionsInTitles?.length && transactionsInTitles.some(el => el.title_asset_id === title.id))) {

                //title.title_local_quotas = JSON.parse(title.title_local_quotas);
                title.title_local_quotas_init = JSON.parse(title.title_local_quotas_init);

                const cotaPu = title.title_local_quotas_init ? title.title_local_quotas_init.find(el => el.date_quota == moment.utc(title.purchase_date).format('DD/MM/YYYY')) : null;
                title.purchase_pu = title.title_local_quotas_init
                    && cotaPu ?
                    cotaPu.pu_anb : null

                title.totalRescuesAmount = 0
                title.totalAmortizationAmount = 0
                title.totalApplicationsAmount = 0

                if (groupedTransactions[title.id] && groupedTransactions[title.id][period]) {

                    title.totalApplicationsAmount = groupedTransactions[title.id][period][1]
                    title.totalRescuesAmount = groupedTransactions[title.id][period][2]
                    title.totalAmortizationAmount = groupedTransactions[title.id][period][3]

                    finalTotals.totalApplicationsAmount += title.totalApplicationsAmount;
                    finalTotals.totalRescuesAmount += title.totalRescuesAmount;
                    finalTotals.totalAmortizationAmount += title.totalAmortizationAmount;

                    if (title.sector === 'PUBLICO') {
                        finalTotalsPublic.totalApplicationsAmount += title.totalApplicationsAmount;
                        finalTotalsPublic.totalRescuesAmount += title.totalRescuesAmount;
                        finalTotalsPublic.totalAmortizationAmount += title.totalAmortizationAmount;
                    } else {
                        finalTotalsPrivate.totalApplicationsAmount += title.totalApplicationsAmount;
                        finalTotalsPrivate.totalRescuesAmount += title.totalRescuesAmount;
                        finalTotalsPrivate.totalAmortizationAmount += title.totalAmortizationAmount;
                    }
                }

                title.balance_now = parseFloat(title.balance_now);
                title.balance_before = parseFloat(title.balance_before);

                finalTotals.balance_now += title.balance_now;
                finalTotals.balance_before += title.balance_before;

                if (title.sector === 'PUBLICO') {
                    finalTotalsPublic.balance_now += title.balance_now;
                    finalTotalsPublic.balance_before += title.balance_before;
                } else {
                    finalTotalsPrivate.balance_now += title.balance_now;
                    finalTotalsPrivate.balance_before += title.balance_before;
                };

                const purchaseAmount = title.price_title * title.quantity_purchased;
                finalTotals.purchaseAmount += purchaseAmount;
                finalTotals.currentAmount += parseFloat(title.balance_now);

                if (title.sector === 'PUBLICO') {
                    finalTotalsPublic.purchaseAmount += purchaseAmount;
                    finalTotalsPublic.currentAmount += parseFloat(title.balance_now);
                } else {
                    finalTotalsPrivate.purchaseAmount += purchaseAmount;
                    finalTotalsPrivate.currentAmount += parseFloat(title.balance_now);
                };

                title.rent = title.balance_now ? getSimpleRentTitle(title) : 0;
                title.rentMoney = title.balance_now ? getSimpleRentMoneyTitle(title) : 0;

                finalTotals.rent = getSimpleRentTitle(finalTotals);
                finalTotals.rentMoney = getSimpleRentMoneyTitle(finalTotals);

                if (title.sector === 'PUBLICO') {
                    finalTotalsPublic.rent = getSimpleRentTitle(finalTotalsPublic);
                    finalTotalsPublic.rentMoney = getSimpleRentMoneyTitle(finalTotalsPublic);
                } else {
                    finalTotalsPrivate.rent = getSimpleRentTitle(finalTotalsPrivate);
                    finalTotalsPrivate.rentMoney = getSimpleRentMoneyTitle(finalTotalsPrivate);
                };
                activesTitles.push(title);
            }

        });

        return {
            allTitles: activesTitles,
            totals: finalTotals,
            finalTotalsPublic,
            finalTotalsPrivate
        };
    };

    return undefined;

}

export async function getAnaliticTitlesByPeriod(clientId, startDate, endDate, month, year, isPeriodQuery) {


    const response = await getClientAllAssetsTitleByDateAndRegimeWithInits(clientId, startDate, endDate, null, isPeriodQuery);

    console.log("Response titles xxx: ", response);
    const responseTitleTransactions = await sumTitlesTransactionsInPeriodByTitleAssetIdAndOperation(clientId, startDate, endDate);

    const { month: monthStartdate, year: yearStartDate } = getDayMonthYearByStringDate(startDate);
    const startDateBefore = getMonthAgo(monthStartdate, yearStartDate, 2);

    const responseTitlesBalances = await BalancesValidationsAPI.getBalancesByPeriodAndTitleAssetsIds(clientId, startDateBefore, endDate);

    console.log("response: ", response);
    console.log("responseTitleTransactions: ", responseTitleTransactions);
    console.log("responseTitlesBalances: ", responseTitlesBalances);

    const startPeriodPrev = startDateBefore.substring(3);
    const startPeriod = startPeriodPrev.startsWith("0") ? startPeriodPrev.substring(1) : startPeriodPrev;
    const endPeriodPrev = endDate.substring(3);
    const endPeriod = endPeriodPrev.startsWith("0") ? endPeriodPrev.substring(1) : endPeriodPrev;

    console.log("startPeriod: ", startPeriod);
    console.log("endPeriod: ", endPeriod);

    const allPeriods = getAllPeriods(startPeriod, endPeriod);

    console.log('allPeriods: ', allPeriods);

    const allTransactionsSumsByPeriod = responseTitleTransactions.data;
    const allBalancesByPeriod = responseTitlesBalances.data;
    const arrayTitleAssets = response.body.rows.map((title) => {

        title.title_local_quotas_init = JSON.parse(title.title_local_quotas_init);

        const cotaPu = title.title_local_quotas_init ? title.title_local_quotas_init.find(el => el.date_quota == moment.utc(title.purchase_date).format('DD/MM/YYYY')) : null;
        title.purchase_pu = title.title_local_quotas_init
            && cotaPu ?
            cotaPu.pu_anb : null

        return {
            ...title,
            titleAssetId: title.id,
            transactionsSumsPerionds: allTransactionsSumsByPeriod[title.id] ? {
                ...allTransactionsSumsByPeriod[title.id]
            } : null,
            balancesPeriods: allBalancesByPeriod[title.id] ? {
                ...allBalancesByPeriod[title.id]
            } : null

        }
    })

    console.log("arrayTitleAssets: ", arrayTitleAssets);
    //Calcular as rentabilidades de cada ativo de título para cada allPeriods
    const totalsPublic = {
        rentPeriod: 0,
        rentMoneyPeriod: 0,

        purchaseAmount: 0,
        currentAmount: 0,

        balanceNow: 0,
        balanceBefore: 0,
        totalRescuesAmount: 0,
        totalAmortizationAmount: 0,
        totalApplicationsAmount: 0
    }
    const totalsPrivate = {
        rentPeriod: 0,
        rentMoneyPeriod: 0,

        purchaseAmount: 0,
        currentAmount: 0,

        balanceNow: 0,
        balanceBefore: 0,
        totalRescuesAmount: 0,
        totalAmortizationAmount: 0,
        totalApplicationsAmount: 0
    }


    const totals = {

        purchaseAmount: 0,
        currentAmount: 0,

        rentPeriod: 0,
        rentMoneyPeriod: 0,

        balanceNow: 0,
        balanceBefore: 0,
        totalRescuesAmount: 0,
        totalAmortizationAmount: 0,
        totalApplicationsAmount: 0
    }


    const titleAssetMap = {};
    arrayTitleAssets.forEach(titleAsset => {

        const { balancesPeriods, transactionsSumsPerionds } = titleAsset;

        // let captalizationPeriod = 1;
        // let moneyInPeriod = 0;

        allPeriods[yearStartDate].forEach((loopPeriod, index) => {

            const balanceNow = balancesPeriods[loopPeriod]
            if (balanceNow != null) {
                const balanceBefore = balancesPeriods[getPeriodBefore(loopPeriod)];

                const applicationsInPeriod = transactionsSumsPerionds?.[loopPeriod]?.applications ?? 0;
                const rescuesInPeriod = transactionsSumsPerionds?.[loopPeriod]?.rescues ?? 0;
                const amortizationsInPeriod = transactionsSumsPerionds?.[loopPeriod]?.amortizations ?? 0;

                if (titleAsset.sector === 'PUBLICO') {
                    totalsPublic.totalApplicationsAmount += applicationsInPeriod;
                    totalsPublic.totalRescuesAmount += rescuesInPeriod;
                    totalsPublic.totalAmortizationAmount += amortizationsInPeriod;
                } else if (titleAsset.sector === 'PRIVADO') {
                    totalsPrivate.totalApplicationsAmount += applicationsInPeriod;
                    totalsPrivate.totalRescuesAmount += rescuesInPeriod;
                    totalsPrivate.totalAmortizationAmount += amortizationsInPeriod;
                };

                totals.totalApplicationsAmount += applicationsInPeriod;
                totals.totalRescuesAmount += rescuesInPeriod;
                totals.totalAmortizationAmount += amortizationsInPeriod;

                if (index === 0) {
                    totals.balanceBefore += balanceBefore ?? 0;
                    if (titleAsset.sector === 'PUBLICO') {
                        totalsPublic.balanceBefore += balanceBefore ?? 0;
                    } else if (titleAsset.sector === 'PRIVADO') {
                        totalsPrivate.balanceBefore += balanceBefore ?? 0;
                    }
                }

                if (!titleAssetMap[titleAsset.id]) {
                    titleAssetMap[titleAsset.id] = {
                        applications: 0,
                        rescues: 0,
                        amortizations: 0,
                        balanceBefore: 0,
                        balanceNow: 0,
                    }
                }

                titleAssetMap[titleAsset.id].applications += applicationsInPeriod ?? 0;
                titleAssetMap[titleAsset.id].rescues += rescuesInPeriod ?? 0;
                titleAssetMap[titleAsset.id].amortizations += amortizationsInPeriod ?? 0;
                titleAssetMap[titleAsset.id].balanceNow = balanceNow ?? 0;
                if (index === 0) {
                    titleAssetMap[titleAsset.id].balanceBefore += balanceBefore ?? 0;
                }

            }

        });

        const purchaseAmount = titleAsset.price_title * titleAsset.quantity_purchased;
        totals.purchaseAmount += purchaseAmount;
        totals.currentAmount += parseFloat(titleAsset.balance_now);

        if (titleAsset.sector === 'PUBLICO') {
            totalsPublic.purchaseAmount += purchaseAmount;
            totalsPublic.currentAmount += parseFloat(titleAsset.balance_now);
        } else if (titleAsset.sector === 'PRIVADO') {
            totalsPrivate.purchaseAmount += purchaseAmount;
            totalsPrivate.currentAmount += parseFloat(titleAsset.balance_now);
        };


        // titleAsset.rentPeriod = captalizationPeriod; // deve ser formatada
        // titleAsset.rentMoneyPeriod = moneyInPeriod;
    });

    console.log(
        "TESTE ZZZ: ",
        getSimpleRentTitle2(totalsPrivate.balanceNow,
            totalsPrivate.totalApplicationsAmount,
            totalsPrivate.totalRescuesAmount, totalsPrivate.totalAmortizationAmount,
            totalsPrivate.balanceBefore)
    );

    arrayTitleAssets.forEach(titleAsset => {

        if (titleAssetMap[titleAsset.id]) {
            const { applications,
                rescues,
                amortizations,
                balanceBefore,
                balanceNow } = titleAssetMap[titleAsset.id];
            titleAsset.rentPeriod = getSimpleRentTitle2(
                balanceNow,
                applications,
                rescues,
                amortizations,
                balanceBefore
            );
            titleAsset.rentMoneyPeriod = getSimpleRentMoneyTitle2(
                balanceNow,
                applications,
                rescues,
                amortizations,
                balanceBefore
            );
        }
    });

    totalsPublic.rentPeriod = 1;

    console.log("CAPITALIZANDO PUBLICOS /////////////////// ");
    arrayTitleAssets.filter(el => el.sector === 'PUBLICO').forEach(element => {
        console.log("Rent: ", element.rentPeriod);
        //totalsPublic.rentPeriod *= element.rentPeriod;
        totalsPublic.rentMoneyPeriod += element.rentMoneyPeriod;
    });
    const realPublicBalanceBefore = totalsPublic.balanceBefore + totalsPublic.totalApplicationsAmount;
    totalsPublic.rentPeriod = realPublicBalanceBefore != 0 ? (totalsPublic.rentMoneyPeriod / (realPublicBalanceBefore)) * 100 : 0;

    totalsPrivate.rentPeriod = 1;

    console.log("CAPITALIZANDO PRIVADOS /////////////////// ");
    arrayTitleAssets.filter(el => el.sector === 'PRIVADO').forEach(element => {
        console.log("Rent: ", element.rentPeriod);
        //totalsPrivate.rentPeriod *= element.rentPeriod;
        totalsPrivate.rentMoneyPeriod += element.rentMoneyPeriod;
    });
    const realPrivateBalanceBefore = totalsPrivate.balanceBefore + totalsPrivate.totalApplicationsAmount;
    totalsPrivate.rentPeriod = realPrivateBalanceBefore != 0 ? ((totalsPrivate.rentMoneyPeriod) / (realPrivateBalanceBefore)) * 100 : 0;

    //totals.rentPeriod = totalsPublic.rentPeriod * totalsPrivate.rentPeriod;
    totals.rentMoneyPeriod = totalsPublic.rentMoneyPeriod + totalsPrivate.rentMoneyPeriod;

    // totalsPublic.rentPeriod = (totalsPublic.rentPeriod - 1) * 100;
    // totalsPrivate.rentPeriod = (totalsPrivate.rentPeriod - 1) * 100;
    // console.log("### total rentPeriod ###: ", totals.rentPeriod);
    // console.log("### totals.balanceNow: ", totals.balanceNow)
    // console.log("### totals.totalApplicationsAmount: ", totals.totalApplicationsAmount)
    // console.log("### totals.totalRescuesAmount: ", totals.totalRescuesAmount)
    // console.log("### totals.totalAmortizationAmount: ", totals.totalAmortizationAmount)
    // console.log("### totals.balanceBefore: ", totals.balanceBefore)
    // console.log("### dif: ", totals.balanceNow - totals.balanceBefore)

    // console.log("totals.rentMoneyPeriod: ", totals.rentMoneyPeriod);
    // console.log("totals.balanceBefore: ", totals.balanceBefore);
    // console.log("totals.totalApplicationsAmount: ", totals.totalApplicationsAmount);
    totals.rentPeriod = (totals.rentMoneyPeriod / (totals.balanceBefore + totals.totalApplicationsAmount)) * 100;
    // console.log("### totals.rentPeriod: ", totals.rentPeriod);

    // console.log("### arrayTitleAssets ###: ", arrayTitleAssets);

    return {
        allTitles: arrayTitleAssets,
        totalsPublic,
        totalsPrivate,
        totals
    }

}

export function formatInfosToShow(selectedYear, titleAssets, balancesTitlesValidations) {

    //console.log("balancesTitlesValidations: ", balancesTitlesValidations);
    titleAssets.forEach(tAsset => {

        let initAsset = 1;
        const { monthBefore, yearBefore } = getMonthAndYearBefore(moment.utc(tAsset.purchase_date).format('DD/MM/YYYY'));

        //console.log('CALC MONTH BEFORE: ', getMonthAndYearBefore(moment.utc(tAsset.purchase_date).format('DD/MM/YYYY')));

        const y = yearBefore;
        //console.log('y: ', y);
        //console.log('selectedYear: ', selectedYear);
        //let y = parseInt(moment.utc(tAsset.purchase_date).format('YYYY'))
        if (y >= selectedYear) { //Se o ano de inicio do asset for maior ou igual ao ano selecionado

            if (y == selectedYear) {
                //Se o ano for igual inicia-se a partir do mês do inicio do asset
                initAsset = monthBefore;
                console.log('initAsset: ', initAsset);
                //initAsset = parseInt(moment.utc(tAsset.purchase_date).format('MM'));
            } else {
                //Se o ano for maior o asset não é exibido
                initAsset = 0
            }

        }

        //modelo month
        // {
        //     id: //balance_title_validation_id
        //     balance: //saldo
        //     quota_amount: //saldo em qtd cotas
        //     month_validation: //saldo em qtd cotas
        //     year_validation: //saldo em qtd cotas
        // }

        tAsset.yearValidation = {
            init: initAsset,
            months: {
                1: null,
                2: null,
                3: null,
                4: null,
                5: null,
                6: null,
                7: null,
                8: null,
                9: null,
                10: null,
                11: null,
                12: null,
            }

        }

        balancesTitlesValidations.forEach(bv => {

            if (tAsset.id == bv.title_asset_id) {

                if (bv.year_validation == selectedYear) {
                    tAsset.yearValidation.months[bv.month_validation] = {
                        id: bv.id, //balance_title_validation_id
                        balance: bv.balance, //saldo
                        quota_amount: bv.quota_amount, //saldo em qtd cotas
                        month_validation: bv.month_validation, //saldo em qtd cotas
                        year_validation: bv.year_validation,//saldo em qtd cotas
                    }
                } else {

                    //Inicizando o o saldo anterior ao ano
                    tAsset.yearValidation.lastBalance = {
                        id: bv.id, //balance_title_validation_id
                        balance: bv.balance, //saldo
                        quota_amount: bv.quota_amount, //saldo em qtd cotas
                        month_validation: bv.month_validation, //saldo em qtd cotas
                        year_validation: bv.year_validation,//saldo em qtd cotas
                    }
                }
            }


        });

    });


    console.log("Formatted titles: ", titleAssets);
    return titleAssets;
}


